import * as d3 from 'd3';
import { Point2D } from 'src/app/shared/interfaces';
import { D3GraphCanvas } from '../../d3-graphs-shared/d3-graph-canvas';
import {
  CategoricalDataLegendConfig,
  D3ColorFunction,
  D3Selection,
  Dimensions,
  GraphCircularTreeLegendContent,
  LegendType,
  TreeGraphMetadataValue,
  isSequentialColorFn,
} from '../graph-circular-tree.model';

export class CategoricalDataLegendContent implements GraphCircularTreeLegendContent {
  readonly legendType: LegendType = 'categorical';
  private readonly tips: D3Selection<SVGGElement>;
  private readonly labels: D3Selection<SVGGElement>;
  private readonly tooltipContainer: D3Selection<SVGForeignObjectElement>;

  constructor(
    readonly parent: D3Selection<SVGGElement>,
    private readonly config: CategoricalDataLegendConfig,
    private readonly canvas: D3GraphCanvas,
  ) {
    this.tips = this.parent.append('g');
    this.labels = this.parent.append('g');
    this.tooltipContainer = this.parent
      .append('foreignObject')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('overflow', 'visible')
      .attr('pointer-events', 'none');
  }

  render(values: TreeGraphMetadataValue[], colorFn: D3ColorFunction, origin: Point2D): Dimensions {
    // Perform clean up before rendering
    this.tips.selectAll('*').remove();
    this.labels.selectAll('*').remove();

    // Default to black if the color function is for continuous data
    const categoricalColorFn =
      colorFn == null || isSequentialColorFn(colorFn) ? d3.scaleOrdinal(values, []) : colorFn;

    const labelYOffset = origin.y + this.config.labelFontSize / 2;
    const legendLabelX = origin.x + this.config.tipRadius * 2 + this.config.padding;
    const groupsToDisplay = values.slice(0, this.config.maxGroups);
    // Render tips & labels
    this.tips
      .selectAll('circle')
      .data(groupsToDisplay)
      .join('circle')
      .attr('tooltip', (group) => group)
      .attr('r', this.config.tipRadius)
      .attr('cx', origin.x + this.config.tipRadius)
      .attr('cy', (_, i) => i * (this.config.labelFontSize + this.config.ySpacing) + labelYOffset)
      .attr('stroke', '#000')
      .attr('stroke-width', 1)
      .attr('fill', (group) => categoricalColorFn(group));

    this.labels
      .selectAll('text')
      .data(groupsToDisplay)
      .join('text')
      .attr('tooltip', (group) => group)
      .attr('x', legendLabelX)
      .attr('y', (_, i) => i * (this.config.labelFontSize + this.config.ySpacing) + labelYOffset)
      .attr('alignment-baseline', 'middle')
      .text((rawGroup) => {
        const group = rawGroup == null ? 'Unknown' : rawGroup.toString();
        if (group.length > this.config.maxLabelLength) {
          return group.slice(0, this.config.maxLabelLength - 3) + '...';
        }
        return group;
      });

    this.tips
      .selectAll('circle')
      .nodes()
      .forEach((circle) => {
        const node = d3.select(circle as SVGCircleElement);
        this.addLegendTooltip(node);
      });
    this.labels
      .selectAll('text')
      .nodes()
      .forEach((text) => {
        const node = d3.select(text as SVGTextElement);
        this.addLegendTooltip(node);
      });

    if (values.length > this.config.maxGroups) {
      this.labels
        .append('text')
        .attr('x', legendLabelX)
        .attr(
          'y',
          groupsToDisplay.length * (this.config.labelFontSize + this.config.ySpacing) +
            labelYOffset,
        )
        .text('...');
    }

    let maxY = 0;
    let longestLegendLabelWidth = 0;

    for (const node of this.labels.selectAll('text').nodes()) {
      const label = node as SVGTextElement;
      const labelY = parseInt(label.getAttribute('y'));
      const labelWidth = this.canvas.measureText(label.textContent).width;
      if (labelY > maxY) {
        maxY = labelY;
      }
      if (labelWidth > longestLegendLabelWidth) {
        longestLegendLabelWidth = labelWidth;
      }
    }

    return {
      height: maxY - origin.y + this.config.labelFontSize,
      width: longestLegendLabelWidth + legendLabelX,
    };
  }

  /**
   * Add a tooltip for a d3 selection of elements in legend. The content of the tooltip is read from the node's `tooltip` attribute.
   *
   * @param node a node in legend
   * @private
   */
  private addLegendTooltip(node: d3.Selection<any, unknown, null, undefined>) {
    const tooltip = this.tooltipContainer
      .append('xhtml:div')
      .attr('class', 'graph-circular-tree-tooltip');

    const show = (text: string, x: number, y: number) => {
      const mouseOffsetX = 10;
      const mouseOffsetY = 10;

      let usedX = x + mouseOffsetX;
      let usedY = y + mouseOffsetY;

      const tooltipBounds = (tooltip.node() as HTMLDivElement).getBoundingClientRect();
      const containerBounds = this.tooltipContainer.node().getBBox();

      if (usedX + tooltipBounds.width > containerBounds.width) {
        usedX -= tooltipBounds.width;
      }

      if (usedY + tooltipBounds.height > containerBounds.height) {
        usedY -= tooltipBounds.height;
      }

      tooltip
        .text(text)
        .style('white-space', 'normal')
        .style('visibility', 'visible')
        .style('transform', `translate(${usedX}px, ${usedY}px)`);
    };

    const hide = () => {
      tooltip.style('visibility', 'hidden');
    };

    node
      .on('mousemove.tooltip', (event: MouseEvent) => {
        if (node.attr('tooltip')) {
          show(node.attr('tooltip'), event.offsetX, event.offsetY);
        }
      })
      .on('mouseleave.tooltip', () => hide());
  }
}
