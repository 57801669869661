import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { CleanUp } from 'src/app/shared/cleanup';
import { ColorPaletteID } from '../color-palette.model';
import { ColorPaletteService } from '../color-palette.service';

@Component({
  selector: 'bx-color-palette',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './color-palette.component.html',
  styleUrls: ['./color-palette.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPaletteComponent extends CleanUp implements OnInit {
  /** The ID of the palette to display */
  @Input() set palette(palette: ColorPaletteID) {
    this.palette$.next(palette);
  }

  /**
   * The number of color swatches to include in the palette. Odd numbers from
   * 5-11 work well. Defaults to 7.
   */
  @Input() set numSwatches(numSwatches: number) {
    this.numSwatches$.next(numSwatches);
  }

  private readonly palette$ = this.completeOnDestroy(
    new BehaviorSubject<ColorPaletteID | null>(null),
  );
  private readonly numSwatches$ = this.completeOnDestroy(new BehaviorSubject<number>(7));
  swatchColors$: Observable<string[]>;

  constructor(private readonly colorPaletteService: ColorPaletteService) {
    super();
  }

  ngOnInit(): void {
    this.swatchColors$ = combineLatest([this.palette$, this.numSwatches$]).pipe(
      map(([palette, numSwatches]) => {
        if (palette == null) {
          // Display blank palette
          return Array.from({ length: numSwatches }, () => '#fff');
        }
        return this.colorPaletteService.getSwatches(palette, numSwatches);
      }),
    );
  }
}
