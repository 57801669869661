@if (widget) {
  <button mat-icon-button class="widget-remover" (click)="removeWidget()">
    <mat-icon>cancel</mat-icon>
  </button>
  <bx-report-widget-title
    [widgetID]="widget.id"
    [title]="widget.title"
    [notes]="widget.notes"
    [showPlaceholders]="this.showControls"
  >
  </bx-report-widget-title>
  <table class="table table-sm table-striped w-100 mb-0 report-grid-theme">
    <thead>
      <tr>
        @for (header of widget.data?.body?.headers; track header; let i = $index) {
          <th [style.textAlign]="columnAlign[i]">{{ header }}</th>
        }
      </tr>
    </thead>
    <tbody>
      @for (row of widget.data?.body?.rows; track row) {
        <tr>
          @for (cell of row; track cell; let i = $index) {
            <td [style.textAlign]="columnAlign[i]">{{ cell }}</td>
          }
        </tr>
      }
    </tbody>
  </table>
  @if (widget.data?.body?.rows?.length === 0) {
    <div>No data found</div>
  }
}
