import * as d3 from 'd3';
import {
  ContinuousDataLegendConfig,
  D3ColorFunction,
  D3Selection,
  GraphCircularTreeLegendContent,
  LegendType,
  TreeGraphMetadataValue,
  isSequentialColorFn,
} from '../graph-circular-tree.model';
import { Point2D } from 'src/app/shared/interfaces';

export class ContinuousDataLegendContent implements GraphCircularTreeLegendContent {
  readonly legendType: LegendType = 'continuous';

  constructor(
    readonly parent: D3Selection<SVGGElement>,
    private readonly config: ContinuousDataLegendConfig,
  ) {}

  render(
    _values: TreeGraphMetadataValue[],
    colorFn: D3ColorFunction,
    origin: Point2D,
  ): { height: number; width: number } {
    // Clean up elements from previous render
    this.parent.selectAll('*').remove();
    // Default to black if the color palette is not for continuous data
    const color = isSequentialColorFn(colorFn) ? colorFn : d3.scaleSequential(() => '#000');
    const minSamples = Math.min(color.domain().length, color.range().length);
    this.parent
      .append('image')
      .attr('x', origin.x)
      .attr('y', origin.y)
      .attr('width', this.config.colorKeyWidth)
      .attr('height', this.config.colorKeyHeight)
      .attr('preserveAspectRatio', 'none')
      .attr(
        'xlink:href',
        this.canvasGradient(
          color.copy().domain(d3.quantize(d3.interpolate(0, 1), minSamples)),
        ).toDataURL(),
      );

    const axisRight = color
      .copy()
      .rangeRound(
        d3.quantize(d3.interpolate(origin.y + this.config.colorKeyHeight, origin.y), minSamples),
      );
    this.parent
      .append('g')
      .attr('transform', `translate(${origin.x},0)`)
      .call(
        d3
          .axisRight(axisRight as any)
          .ticks(this.config.numTicks)
          .tickSize(this.config.colorKeyWidth + this.config.tickSize),
      )
      .attr('font-size', this.config.tickLabelFontSize)
      .call((g) => g.select('.domain').remove());

    const tickWidth = (this.parent.select('g.tick').node() as SVGGElement)?.getBBox()?.width;

    return {
      width: tickWidth || this.config.colorKeyWidth + this.config.tickSize + origin.x,
      height: this.config.colorKeyHeight,
    };
  }

  private canvasGradient(color: (t: number) => string, n = 256): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    canvas.width = 1;
    canvas.height = n;
    const context = canvas.getContext('2d');
    for (let i = 0; i < n; ++i) {
      context.fillStyle = color(i / (n - 1));
      context.fillRect(0, n - i, 1, 1);
    }
    return canvas;
  }
}
