import { FontSize } from '../graph-circular-tree/graph-circular-tree.model';

/**
 * Handles shared canvas operations for the Circular Tree and its legends,
 * such as measuring text and exporting images.
 */
export class D3GraphCanvas {
  private readonly context: CanvasRenderingContext2D;

  constructor(
    private readonly defaultFontSize: number,
    private readonly defaultFontWeight = 'normal',
  ) {
    const canvas = document.createElement('canvas');
    this.context = canvas.getContext('2d');
  }

  /**
   * Measures the on-screen size of text using the canvas.
   *
   * @param text the text to measure
   * @param font the font to measure the text with
   * @returns the text metrics containing size information
   */
  measureText(text: string, font?: FontConfig): TextMetrics {
    if (!font) {
      return this.context.measureText(text);
    }
    this.setFont(font);
    const metrics = this.context.measureText(text);
    this.setFont();
    return metrics;
  }

  /**
   * Removes the canvas element from the DOM.
   */
  destroy(): void {
    this.context.canvas.remove();
  }

  /**
   * Renders SVGs and downloads them as single PNG image file.
   *
   * @param filename the unsuffixed name to give the PNG file
   * @param svgElements the svgs and the positions they should be drawn at
   * @param width the desired width of the image
   * @param height the desired height of the image
   * @returns a Promise that completes when the download has been triggered
   */
  async downloadSvgsAsImage(
    filename: string,
    svgElements: { svg: SVGElement; x: number; y: number }[],
    width: number,
    height: number,
  ): Promise<void> {
    this.context.canvas.width = width;
    this.context.canvas.height = height;

    for (const { svg, x, y } of svgElements) {
      await this.drawSvgToCanvas(svg, x, y);
    }
    this.downloadCanvas(filename);
    this.clearCanvas();
  }

  private drawSvgToCanvas(svg: SVGElement, x: number, y: number): Promise<void> {
    return new Promise<void>((resolve) => {
      const image = new Image();
      image.onload = () => {
        this.context.drawImage(image, x, y);
        resolve();
      };
      image.src = this.createImageURLFromSVG(svg);
    });
  }

  private createImageURLFromSVG(svg: SVGElement) {
    const clonedSvg = svg.cloneNode(true) as SVGElement;
    clonedSvg.querySelector('foreignObject')?.remove();

    const svgSerialised = new XMLSerializer().serializeToString(clonedSvg);
    const svgBlob = new Blob([svgSerialised], { type: 'image/svg+xml;charset=utf-8' });
    return window.URL.createObjectURL(svgBlob);
  }

  private downloadCanvas(filename: string) {
    const link = document.createElement('a');
    link.download = filename;
    link.href = this.context.canvas.toDataURL();
    link.click();
    link.remove();
  }

  private clearCanvas() {
    this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);
    this.setFont();
  }

  private setFont(
    font: FontConfig = {
      fontWeight: this.defaultFontWeight,
      fontSize: `${this.defaultFontSize}px`,
    },
  ) {
    this.context.font = `${font.fontWeight} ${font.fontSize} sans-serif`;
  }
}

export interface FontConfig {
  fontWeight: string;
  fontSize: FontSize;
}
