import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ImageWidget, ReportWidgetComponent } from '../../report.model';
import { map, tap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { DocumentHttpV2Service } from 'src/nucleus/v2/document-http.v2.service';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { ReportWidgetTitleComponent } from '../../report-widget-title/report-widget-title.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-image-widget',
  templateUrl: './image-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatLegacyButtonModule, MatIconModule, ReportWidgetTitleComponent, AsyncPipe],
})
export class ImageWidgetComponent implements OnInit, OnDestroy, ReportWidgetComponent {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column';
  @Input() widget: ImageWidget;
  @Input() showControls = true;
  @Output() widgetReady = new EventEmitter<void>();
  @Output() widgetRemoved = new EventEmitter<string>();

  imageURL$: Observable<any>;

  private objectURL: string;

  constructor(
    private documentHttpService: DocumentHttpV2Service,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.imageURL$ = this.documentHttpService
      .getDocumentPart(this.widget.data.documentID, 'FILE', 'blob')
      .pipe(
        map((blob) => URL.createObjectURL(blob)),
        tap((url) => (this.objectURL = url)),
        map((url) => this.sanitizer.bypassSecurityTrustUrl(url)),
      );
  }

  ngOnDestroy(): void {
    this.cleanupImage();
  }

  removeWidget() {
    this.widgetRemoved.emit(this.widget.id);
  }

  private cleanupImage() {
    if (this.objectURL) {
      URL.revokeObjectURL(this.objectURL);
    }
  }
}
