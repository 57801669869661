import { Directive, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, filter, takeUntil } from 'rxjs';
import { CleanUp } from 'src/app/shared/cleanup';
import { patchWidgetData } from './report-store/report-state.actions';
import { selectReportWidget } from './report-store/report-state.selectors';
import { ReportWidget, ReportWidgetComponent } from './report.model';
import { AppState } from 'src/app/core/core.store';

/**
 * Provides methods that are useful for updating widget data in the store.
 * If you don't need this functionality, implement the ReportWidgetComponent
 * interface instead.
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseReportWidgetComponent<T extends ReportWidget>
  extends CleanUp
  implements ReportWidgetComponent
{
  abstract widget: T;
  abstract showControls: boolean;
  abstract widgetReady: EventEmitter<void>;
  abstract widgetRemoved: EventEmitter<string>;
  protected widget$: Observable<T>;

  protected constructor(protected readonly store: Store<AppState>) {
    super();
  }

  /**
   * This should be called in ngOnInit by components that extend this class.
   * Initializes the {@link widget$} property, which reflects the widget's value
   * in the store. Also sets up a subscription that updates {@link widget}
   * property with the latest value from the store.
   */
  initWidget$(): void {
    this.widget$ = this.store.select(selectReportWidget(this.widget.id)).pipe(
      filter((widget): widget is T => !!widget),
      takeUntil(this.ngUnsubscribe),
    );
    this.widget$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((widget) => {
      this.widget = widget;
    });
  }

  /**
   * Dispatches an action to the store that patches the data property of the
   * widget.
   *
   * @param patch object containing new values to patch data with
   */
  patchWidgetData(patch: Partial<T['data']>): void {
    this.store.dispatch(
      patchWidgetData({
        payload: { id: this.widget.id, data: patch },
      }),
    );
  }

  /**
   * Emits the widget ID from the {@link widgetRemoved} output.
   */
  removeWidget(): void {
    this.widgetRemoved.emit(this.widget.id);
  }
}
