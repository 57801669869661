@if (graph$ | async; as chart) {
  @if (chart.error) {
    <bx-page-message class="flex-grow-1">
      {{ chart.error }}
    </bx-page-message>
  } @else {
    @if (loading$ | async) {
      <bx-page-message class="position-absolute w-100 h-100 loading-overlay">
        <bx-loading></bx-loading>
      </bx-page-message>
    }
    @switch (chart.graph.type) {
      @case (GraphEnum.COLUMN) {
        <bx-column-chart
          class="overflow-hidden"
          #graph
          [animations]="animations"
          [data]="chart.graph.data"
          [title]="hideTitle ? undefined : chart.graph.title"
          [xAxisTitle]="chart.graph.xAxisTitle"
          [yAxisTitle]="chart.graph.yAxisTitle"
        >
        </bx-column-chart>
      }
      @case (GraphEnum.STACKED_COLUMN) {
        <bx-stacked-column-chart
          class="overflow-hidden"
          #graph
          [data]="chart.graph.data"
          [title]="hideTitle ? undefined : chart.graph.title"
          [xAxisTitle]="chart.graph.xAxisTitle"
          [yAxisTitle]="chart.graph.yAxisTitle"
          [yAxisRange]="chart.graph.yAxisRange"
          [stacking]="chart.graph.stacking"
          [animations]="chart.graph.animations && animations"
          [showLegend]="chart.graph.showLegend"
          [showDataLabels]="chart.graph.showLabels"
        >
        </bx-stacked-column-chart>
      }
      @case (GraphEnum.HEATMAP) {
        <bx-graph-heatmap
          class="flex-grow-1 flex-shrink-1"
          #graph
          [data]="chart.graph.data"
          [title]="hideTitle ? undefined : chart.graph.title"
          [xAxisTitle]="chart.graph.xAxisTitle"
          [yAxisTitle]="chart.graph.yAxisTitle"
          [animations]="animations"
          [isWrapped]="chart.graph.wrapped"
        >
        </bx-graph-heatmap>
      }
      @case (GraphEnum.HEATMAP_TABLE) {
        <bx-client-grid
          #table
          class="codon-grid w-100 h-100"
          [columnDefs]="chart.graph.data.cols"
          [rowData]="chart.graph.data.rows"
        >
        </bx-client-grid>
      }
      @case (GraphEnum.TREE) {
        <bx-circular-tree-graph
          #graph
          class="h-100"
          [data]="chart.graph.data"
          [tipLabelConfig]="chart.graph.tipLabelConfig"
          [heatmapConfig]="chart.graph.heatmapConfig"
          [branchTransform]="chart.graph.branchTransform"
          [autoColorBranches]="chart.graph.autoColorBranches"
          [coloringMetadataConfig]="chart.graph.coloringMetadataConfig"
          [legendConfig]="chart.graph.legendConfig"
        >
        </bx-circular-tree-graph>
      }
    }
  }
  <bx-graph-sidebar
    class="flex-grow-0 flex-shrink-0"
    [controls]="chart.controls"
    [hideControls]="hideControls"
    [allowControlUpdates]="true"
    (toggle)="onSidebarToggled()"
    (controlsChanged)="onControlsChanged($event)"
  >
  </bx-graph-sidebar>
} @else {
  <bx-page-message class="flex-grow-1">
    <bx-loading></bx-loading>
  </bx-page-message>
}
<ng-template #loading>
  <bx-page-message class="flex-grow-1">
    <bx-loading></bx-loading>
  </bx-page-message>
</ng-template>
