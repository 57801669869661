import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { SequenceLogoSettings, SequenceLogoWidget } from '../../report.model';
import { SequenceLogoDatasource } from '../../../../features/graphs/datasources/sequence-logo-datasource';
import { BaseReportWidgetComponent } from '../../base-report-widget.component';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/core.store';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { ReportWidgetTitleComponent } from '../../report-widget-title/report-widget-title.component';

import { ChartPresenterComponent } from '../../../graphs/chart-presenter/chart-presenter.component';

@Component({
  selector: 'bx-sequence-logo-widget',
  templateUrl: './sequence-logo-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatLegacyButtonModule,
    MatIconModule,
    ReportWidgetTitleComponent,
    ChartPresenterComponent,
  ],
})
export class SequenceLogoWidgetComponent
  extends BaseReportWidgetComponent<SequenceLogoWidget>
  implements OnInit
{
  @HostBinding('class') readonly hostClass = 'd-flex flex-column h-static-3 overflow-y-auto';
  @Input() widget: SequenceLogoWidget;
  @Input() showControls = true;
  @Output() widgetReady = new EventEmitter();
  @Output() widgetRemoved = new EventEmitter<string>();

  datasource: SequenceLogoDatasource;

  constructor(store: Store<AppState>) {
    super(store);
  }

  ngOnInit() {
    this.createSequenceLogoSettings();
  }

  setChartOptions(settings: SequenceLogoSettings) {
    this.patchWidgetData({ settings });
  }

  createSequenceLogoSettings() {
    let settings = this.widget.data.settings;
    if (!settings) {
      settings = {
        colorScheme: 'Default',
        isSetToEntropy: false,
      };
      this.patchWidgetData({ settings });
    }
    this.datasource = new SequenceLogoDatasource(
      this.widget.data.body.length,
      this.widget.data.body,
      true,
      this.widget.data.body[0].type,
      settings,
    );
  }
}
