import {
  interpolateBlues,
  interpolateCividis,
  interpolateGreens,
  interpolatePlasma,
  interpolateRainbow,
  interpolateReds,
  interpolateViridis,
  schemeCategory10,
  schemePaired,
  schemeSet1,
  schemeTableau10,
} from 'd3';

// See https://d3js.org/d3-scale-chromatic for documentation on D3's color schemes

export type ColorPaletteID =
  | 'category10'
  | 'paired'
  | 'set1'
  | 'tableau10'
  | 'viridis'
  | 'plasma'
  | 'cividis'
  | 'rainbow'
  | 'blues'
  | 'greens'
  | 'reds';
// Diverging palettes that we may want to use in future:
// | 'pinkGreen'
// | 'redBlue'
// | 'purpleOrange'

export const ColorPaletteKind = {
  CATEGORICAL: 'categorical',
  SEQUENTIAL: 'sequential',
  // DIVERGING: 'diverging',
} as const;
export type ColorPaletteKind = (typeof ColorPaletteKind)[keyof typeof ColorPaletteKind];

interface BaseColorPalette {
  id: ColorPaletteID;
  name: string;
  /**
   * The maximum number of categories that this palette can display. The value will be:
   * - The number of colors in the palette for categorical color palettes
   * - A dev-defined subjective number for sequential color palettes
   * - Zero for color palettes that do not work well with categorical data
   * This value is ignored for sequential palettes when displaying continuous data.
   */
  maxCategories: number;
}
export interface SequentialColorPalette extends BaseColorPalette {
  kind: 'sequential';
  interpolator: (value: number) => string;
}
export interface CategoricalColorPalette extends BaseColorPalette {
  kind: 'categorical';
  colors: readonly string[];
}
export type ColorPalette = SequentialColorPalette | CategoricalColorPalette;

function categoricalPalette(
  id: ColorPaletteID,
  name: string,
  maxCategories: number,
  colors: readonly string[],
): CategoricalColorPalette {
  return { id, name, maxCategories, colors, kind: ColorPaletteKind.CATEGORICAL };
}

function sequentialPalette(
  id: ColorPaletteID,
  name: string,
  maxCategories: number,
  interpolator: (value: number) => string,
): SequentialColorPalette {
  return { id, name, maxCategories, interpolator, kind: ColorPaletteKind.SEQUENTIAL };
}

export const ColorPalette = {
  // Categorical color palettes
  category10: categoricalPalette('category10', 'Category', 10, schemeCategory10),
  paired: categoricalPalette('paired', 'Paired', 12, schemePaired),
  set1: categoricalPalette('set1', 'Set', 9, schemeSet1),
  tableau10: categoricalPalette('tableau10', 'Tableau', 10, schemeTableau10),
  // Continuous multi-color palettes
  viridis: sequentialPalette('viridis', 'Viridis', 0, interpolateViridis),
  plasma: sequentialPalette('plasma', 'Plasma', 0, interpolatePlasma),
  cividis: sequentialPalette('cividis', 'Cividis', 0, interpolateCividis),
  // Rainbow is the continuous palette that works best with categorical data with large numbers of categories
  rainbow: sequentialPalette('rainbow', 'Rainbow', Infinity, interpolateRainbow),
  // Continuous single-color
  blues: sequentialPalette('blues', 'Blues', 0, interpolateBlues),
  greens: sequentialPalette('greens', 'Greens', 0, interpolateGreens),
  reds: sequentialPalette('reds', 'Reds', 0, interpolateReds),
  // Diverging palettes (not yet implemented)
  // pinkGreen: sequentialPalette('pinkGreen', 'Pink-Green', 0, interpolatePiYG, 'diverging'),
  // redBlue: sequentialPalette('redBlue', 'Red-Blue', 0, interpolateRdBu, 'diverging'),
  // purpleOrange: sequentialPalette('purpleOrange', 'Purple-Orange', 0, interpolatePuOr, 'diverging'),
} as const satisfies Record<ColorPaletteID, ColorPalette>;

export const ColorPaletteIDs = Object.keys(ColorPalette) as ColorPaletteID[];
export const ColorPalettes: ColorPalette[] = Object.values(ColorPalette);

/**
 * Type guard for color palette IDs.
 *
 * @param obj to check
 * @returns true if obj is a ColorPaletteID
 */
export function isColorPaletteID(obj: unknown): obj is ColorPaletteID {
  return ColorPaletteIDs.includes(obj as any);
}

/**
 * Criteria for filtering palettes. If multiple filter properties are specified, they all
 * must be met for a palette to be returned.
 */
export interface PaletteFilter {
  /** The kind of palette */
  kind?: ColorPaletteKind;
  /** The number of categories the palette needs to support */
  numCategories?: number;
}
