@if (!hideControls) {
  <bx-collapsible-panel
    panelClass="w-static-3 p-3 overflow-auto"
    (collapseChange)="toggle.next(!$event)"
  >
    <ng-content></ng-content>
    <form [formGroup]="form">
      @for (control of controlsToUse$ | async; track controlNameTracking($index, control)) {
        @switch (control.type) {
          @case (ControlType.COMPONENT) {
            @if (!control.layout || control.layout === 'inline') {
              <div
                class="row mb-3"
                [ngbTooltip]="control.tooltip"
                [class.opacity-50]="control.disabled"
              >
                <label class="col-4 col-form-label">{{ control.label | withColon }}</label>
                <div class="col-8">
                  <ng-container
                    *ngComponentOutlet="control.component; injector: control.injectorWithForm"
                  >
                  </ng-container>
                </div>
              </div>
            } @else {
              <div
                class="mb-3"
                [ngbTooltip]="control.tooltip"
                [class.opacity-50]="control.disabled"
              >
                <label class="form-label hidden-if-empty">{{ control.label | withColon }}</label>
                <ng-container
                  *ngComponentOutlet="control.component; injector: control.injectorWithForm"
                >
                </ng-container>
              </div>
            }
          }
          @case (ControlType.SELECT) {
            @if (!control.layout || control.layout === 'inline') {
              <div
                class="row mb-2 row"
                [ngbTooltip]="control.tooltip"
                [class.opacity-50]="control.disabled"
              >
                <label class="col-4 col-form-label" [for]="idPrefix + control.name">{{
                  control.label | withColon
                }}</label>
                <div class="col-8">
                  <bx-select
                    [id]="control.name"
                    [formControl]="form.get(control.name)"
                    [selectOptions]="control.options"
                    [smallSize]="false"
                  >
                  </bx-select>
                </div>
              </div>
            } @else {
              <div
                class="mb-3"
                [ngbTooltip]="control.tooltip"
                [class.opacity-50]="control.disabled"
              >
                <label class="form-label" [for]="idPrefix + control.name">{{
                  control.label | withColon
                }}</label>
                <bx-select
                  [id]="idPrefix + control.name"
                  [formControl]="form.get(control.name)"
                  [selectOptions]="control.options"
                  [smallSize]="false"
                >
                </bx-select>
              </div>
            }
          }
          @case (ControlType.CHECKBOX) {
            <div
              class="mb-3 form-check"
              [ngbTooltip]="control.tooltip"
              [class.opacity-50]="control.disabled"
            >
              <input
                class="form-check-input"
                type="checkbox"
                [id]="idPrefix + control.name"
                [name]="control.name"
                [formControl]="form.get(control.name)"
                [attr.disabled]="control.disabled ? true : null"
              />
              <label [for]="idPrefix + control.name" class="form-check-label">
                {{ control.label }}
              </label>
            </div>
          }
          @case (ControlType.INPUT) {
            @if (!control.layout || control.layout === 'inline') {
              <div
                class="row mb-2 row"
                [ngbTooltip]="control.tooltip"
                [class.opacity-50]="control.disabled"
              >
                <label class="col-4 col-form-label" [for]="idPrefix + control.name">{{
                  control.label | withColon
                }}</label>
                <div class="col-8">
                  <input
                    class="form-control"
                    [id]="idPrefix + control.name"
                    [type]="control.valueType"
                    [name]="control.name"
                    [formControl]="form.get(control.name)"
                  />
                </div>
              </div>
            } @else {
              <div
                class="mb-3"
                [ngbTooltip]="control.tooltip"
                [class.opacity-50]="control.disabled"
              >
                <div>
                  <label class="form-label" [for]="idPrefix + control.name">{{
                    control.label | withColon
                  }}</label>
                  <input
                    class="form-control"
                    [id]="idPrefix + control.name"
                    [type]="control.valueType"
                    [name]="control.name"
                    [formControl]="form.get(control.name)"
                  />
                </div>
              </div>
            }
          }
          @case (ControlType.PALETTE) {
            <div class="mb-3" [ngbTooltip]="control.tooltip" [class.opacity-50]="control.disabled">
              <label class="form-label" [for]="idPrefix + control.name">{{
                control.label | withColon
              }}</label>
              <bx-select-color-palette
                [id]="idPrefix + control.name"
                class="row"
                [formControl]="form.get(control.name)"
                [isCategorical]="control.isCategorical"
                [numCategories]="control.numCategories"
              ></bx-select-color-palette>
            </div>
          }
        }
      }
    </form>
  </bx-collapsible-panel>
}
