import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  GraphDatasource,
  GraphSidebarDatasourceResponse,
  GraphTypeEnum,
  isDatasourceErrorResponse,
} from '../graph-sidebar';
import { BaseChartComponent } from '../abstract-column-chart/base-chart.component';
import { from, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import {
  catchError,
  filter,
  shareReplay,
  startWith,
  take,
  tap,
  withLatestFrom,
  switchMap,
  map,
  share,
  takeUntil,
} from 'rxjs/operators';
import { ClientGridComponent } from '../../grid/client-grid/client-grid.component';
import { DownloadTableOptions, DownloadTableStackedColumnOptions } from '../exportable-chart';
import { DownloadBlobService } from 'src/app/core/download-blob.service';
import { GridData } from '../graph-util.service';
import { CleanUp } from 'src/app/shared/cleanup';
import { AsyncPipe } from '@angular/common';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { LoadingComponent } from '../../../shared/loading/loading.component';
import { ColumnChartComponent } from '../column-chart/column-chart.component';
import { StackedColumnChartComponent } from '../stacked-column-chart/stacked-column-chart.component';
import { GraphHeatmapComponent } from '../graph-heatmap/graph-heatmap.component';
import { GraphCircularTreeComponent } from '../graph-circular-tree/graph-circular-tree.component';
import { GraphSidebarComponent } from '../graph-sidebar/graph-sidebar.component';

@Component({
  selector: 'bx-chart-presenter',
  templateUrl: './chart-presenter.component.html',
  styleUrls: ['./chart-presenter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PageMessageComponent,
    LoadingComponent,
    ColumnChartComponent,
    StackedColumnChartComponent,
    GraphHeatmapComponent,
    ClientGridComponent,
    GraphCircularTreeComponent,
    GraphSidebarComponent,
    AsyncPipe,
  ],
})
export class ChartPresenterComponent extends CleanUp implements OnInit {
  @HostBinding('class') readonly hostClass = 'flex-grow-1 flex-shrink-1 overflow-hidden d-flex';
  @Input() hideControls = false;
  @Input() hideTitle = false;
  @Input() animations = true;

  @Input() set datasource(datasource: GraphDatasource) {
    this.datasource$.next(datasource);
  }

  @Output() optionsChanged = new EventEmitter<{ [key: string]: any }>();
  @Output() finishedLoading = new EventEmitter();

  @ViewChild('graph') graph: BaseChartComponent;
  @ViewChild('table') table: ClientGridComponent;

  graph$: Observable<GraphSidebarDatasourceResponse>;
  readonly controlsChanged$ = this.completeOnDestroy(new ReplaySubject<any>(1));
  readonly datasource$ = this.completeOnDestroy(new ReplaySubject<GraphDatasource>(1));
  readonly previousResponse$ = this.completeOnDestroy(
    new ReplaySubject<GraphSidebarDatasourceResponse>(1),
  );
  loading$: Observable<boolean>;
  readonly exportHeatmapTable$ = this.completeOnDestroy(new Subject<void>());

  ngOnInit() {
    const initialData$: Observable<GraphSidebarDatasourceResponse> = this.datasource$.pipe(
      switchMap((datasource) => datasource.init()),
      share(),
    );

    const controlValueChangedEvent$ = initialData$.pipe(
      switchMap((initialData) => {
        this.previousResponse$.next(initialData);
        return this.controlsChanged$.pipe(
          withLatestFrom(this.previousResponse$, this.datasource$),
          switchMap(([currentValue, previousResponse, datasource]) => {
            const previousValue = this.getControlValues(previousResponse);
            return this.toObservable(
              datasource.controlValueChanged(previousValue, currentValue),
            ).pipe(tap((value) => this.previousResponse$.next(value)));
          }),
        );
      }),
      tap((graph) => {
        if (!isDatasourceErrorResponse(graph)) {
          this.optionsChanged.emit(graph.options);
        }
      }),
    );

    const tableData$ = this.exportHeatmapTable$.pipe(
      withLatestFrom(this.previousResponse$, this.datasource$),
      switchMap(([_, previousResponse, datasource]) => {
        const previousValue = this.getControlValues(previousResponse);
        return this.toObservable(
          datasource.controlValueChanged(previousValue, { ...previousValue, table: true }),
        );
      }),
      takeUntil(this.ngUnsubscribe),
    );

    this.graph$ = merge(initialData$, controlValueChangedEvent$).pipe(
      catchError((e) => {
        console.error(e);
        return of({
          error: 'Could not load graph.\nAn unexpected error occurred.',
          controls: [],
        });
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    tableData$.subscribe((tableGraph) => {
      if (!isDatasourceErrorResponse(tableGraph)) {
        const fileName =
          tableGraph.options.genes !== undefined
            ? `Gene Combinations ${tableGraph.options.genes}`
            : `Codon Distribution ${tableGraph.options.region.name} ${tableGraph.options.length}`;
        const gridData = tableGraph.graph.data as GridData;
        const headers = gridData.cols.map((col) => col.field);
        const rows = gridData.rows.map((row) => headers.map((header) => row[header]));

        const CSVheader = headers.join(',');
        const CSVbody = rows.map((row: any) => row.join(',')).join('\n');

        DownloadBlobService.download(`${fileName}.csv`, CSVheader + '\n' + CSVbody);
      }
    });

    this.loading$ = merge(
      this.controlsChanged$.pipe(map(() => true)),
      this.datasource$.pipe(map(() => true)),
      this.graph$.pipe(map(() => false)),
    ).pipe(startWith(true), takeUntil(this.ngUnsubscribe), shareReplay(1));

    this.loading$
      .pipe(
        filter((loading) => !loading),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => this.finishedLoading.emit());
  }

  getControlValues(initialData: GraphSidebarDatasourceResponse): { [key: string]: unknown } {
    if (isDatasourceErrorResponse(initialData)) {
      return {};
    } else {
      return initialData.controls.reduce((agg, control) => {
        return {
          ...agg,
          [control.name]: control.defaultOption,
        };
      }, {});
    }
  }

  resize(): void {
    if (this.graph) {
      this.graph.resize();
    }
  }

  get isGraphAvailable() {
    return !!this.graph;
  }

  exportGraph(documentName: string): void {
    if (this.graph) {
      this.graph.downloadImage(documentName);
    }
  }

  exportGraphAsTable(options: DownloadTableOptions | DownloadTableStackedColumnOptions): void {
    if (this.graph && 'downloadTable' in this.graph) {
      this.graph.downloadTable(options);
    } else {
      this.exportHeatmapTable$.next();
    }
  }

  onSidebarToggled() {
    // setTimeout is necessary to let the sidebar expand out before resizing the graph.
    setTimeout(() => this.resize(), 0);
  }

  onControlsChanged(value: any) {
    this.controlsChanged$.next(value);
  }

  get GraphEnum() {
    return GraphTypeEnum;
  }

  private toObservable(
    controlValueChanged:
      | Observable<GraphSidebarDatasourceResponse>
      | Promise<GraphSidebarDatasourceResponse>,
  ): Observable<GraphSidebarDatasourceResponse> {
    return controlValueChanged instanceof Observable
      ? controlValueChanged
      : from(controlValueChanged);
  }
}
