import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ReportStateFacade } from '../report-store/report-state.facade';
import { EditableTextComponent } from '../../../shared/editable-text/editable-text.component';

@Component({
  selector: 'bx-report-widget-title',
  templateUrl: './report-widget-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [EditableTextComponent],
})
export class ReportWidgetTitleComponent implements OnDestroy {
  @Input() widgetID: string;
  @Input() title: string;
  @Input() notes: string;
  @Input() showPlaceholders: boolean;

  titleChanged$ = new Subject<string>();
  notesChanged$ = new Subject<string>();

  private subscriptions = new Subscription();

  constructor(private reportStateFacade: ReportStateFacade) {
    this.subscriptions.add(
      this.titleChanged$.pipe(distinctUntilChanged()).subscribe((title) => {
        this.reportStateFacade.updateWidgetTitle(this.widgetID, title);
      }),
    );

    this.subscriptions.add(
      this.notesChanged$.pipe(distinctUntilChanged()).subscribe((notes) => {
        this.reportStateFacade.updateWidgetNotes(this.widgetID, notes);
      }),
    );
  }

  get titlePlaceholder() {
    return this.showPlaceholders ? 'Add title...' : undefined;
  }

  get notesPlaceholder() {
    return this.showPlaceholders ? 'Add notes...' : undefined;
  }

  ngOnDestroy(): void {
    this.titleChanged$.complete();
    this.notesChanged$.complete();
    this.subscriptions.unsubscribe();
  }
}
