import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../core/core.store';
import { selectAllReportWidgets, selectReportStateFlattened } from './report-state.selectors';
import { Observable } from 'rxjs';
import { NewReportWidget, ReportWidget } from '../report.model';
import {
  addWidget,
  addWidgets,
  clearReport,
  hydrate,
  newReport,
  removeWidget,
  updateCreatedBy,
  updateDate,
  updateTitle,
  updateNotes,
  updateWidget,
  updateWidgetTitle,
  updateWidgetNotes,
} from './report-state.actions';
import { ReportStateFlattened } from './report-state';
import { v4 as UUID } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class ReportStateFacade {
  private readonly reportState$: Observable<ReportStateFlattened>;
  private readonly reportWidgets$: Observable<ReportWidget[]>;

  constructor(private store: Store<AppState>) {
    this.reportState$ = store.pipe(select(selectReportStateFlattened));

    this.reportWidgets$ = store.pipe(select(selectAllReportWidgets));
  }

  // TODO Must be a better way to do this. Perhaps an action in the root reducer?
  hydrateStore(reportState: ReportStateFlattened) {
    this.store.dispatch(hydrate({ payload: { reportState } }));
  }

  initialiseReport(title: string, date: Date, createdBy: string) {
    this.store.dispatch(newReport({ payload: { title, date, createdBy } }));
  }

  clearReport() {
    this.store.dispatch(clearReport());
  }

  updateTitle(title: string) {
    this.store.dispatch(updateTitle({ payload: { title } }));
  }

  updateNotes(notes: string) {
    this.store.dispatch(updateNotes({ payload: { notes } }));
  }

  updateDate(date: Date) {
    this.store.dispatch(updateDate({ payload: { date } }));
  }

  /**
   *
   * @param createdBy - can be email of the user
   */
  updateCreatedBy(createdBy: string) {
    this.store.dispatch(updateCreatedBy({ payload: { createdBy } }));
  }

  addWidget(widget: NewReportWidget) {
    this.store.dispatch(
      addWidget({
        payload: {
          widget: this.widgetFromNewWidget(widget),
        },
      }),
    );
  }

  addWidgets(widgets: NewReportWidget[]) {
    this.store.dispatch(addWidgets({ payload: { widgets: this.widgetsFromNewWidgets(widgets) } }));
  }

  updateWidget(widget: ReportWidget) {
    this.store.dispatch(updateWidget({ payload: { widget } }));
  }

  updateWidgetTitle(id: string, title: string) {
    this.store.dispatch(updateWidgetTitle({ payload: { id, title } }));
  }

  updateWidgetNotes(id: string, notes: string) {
    this.store.dispatch(updateWidgetNotes({ payload: { id, notes } }));
  }

  removeWidget(id: string) {
    this.store.dispatch(removeWidget({ payload: { id } }));
  }

  getReportState(): Observable<ReportStateFlattened> {
    return this.reportState$;
  }

  getReportWidgets(): Observable<ReportWidget[]> {
    return this.reportWidgets$;
  }

  private widgetsFromNewWidgets(newWidgets: NewReportWidget[]): ReportWidget[] {
    return newWidgets.map(this.widgetFromNewWidget);
  }

  private widgetFromNewWidget(newWidget: NewReportWidget): ReportWidget {
    return {
      id: UUID(),
      ...newWidget,
    };
  }
}
