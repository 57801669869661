<button mat-icon-button class="widget-remover" (click)="removeWidget()">
  <mat-icon>cancel</mat-icon>
</button>

<bx-report-widget-title
  [widgetID]="widget.id"
  [title]="widget.title"
  [notes]="widget.notes"
  [showPlaceholders]="this.showControls"
>
</bx-report-widget-title>

@if (imageURL$ | async; as imageURL) {
  <img [src]="imageURL" (load)="widgetReady.emit()" class="w-100" />
}
