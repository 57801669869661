import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  isGraphWidget,
  isImageWidget,
  isResultTableWidget,
  isSequenceLogoWidget,
  isTableWidget,
  ReportJson,
  ReportWidget,
} from './report.model';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  mapTo,
  scan,
  share,
  switchMap,
  tap,
} from 'rxjs/operators';
import { ReportStateFlattened } from './report-store/report-state';
import { ReportStateFacade } from './report-store/report-state.facade';
import { migrateReportJSON } from './report-migrations';
import { DocumentHttpV2Service } from 'src/nucleus/v2/document-http.v2.service';
import { AsyncPipe } from '@angular/common';
import { EditableTextComponent } from '../../shared/editable-text/editable-text.component';
import { TableWidgetComponent } from './widgets/table-widget/table-widget.component';
import { ResultTableWidgetComponent } from './widgets/result-table-widget/result-table-widget.component';
import { SequenceLogoWidgetComponent } from './widgets/sequence-logo-widget/sequence-logo-widget.component';
import { GraphWidgetComponent } from './widgets/graph-widget/graph-widget.component';
import { ImageWidgetComponent } from './widgets/image-widget/image-widget.component';

@Component({
  selector: 'bx-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    EditableTextComponent,
    TableWidgetComponent,
    ResultTableWidgetComponent,
    SequenceLogoWidgetComponent,
    GraphWidgetComponent,
    ImageWidgetComponent,
    AsyncPipe,
  ],
})
export class ReportComponent implements OnInit, OnDestroy {
  @HostBinding('class.pdf-render-mode') isPdfView = false;
  reportState$: Observable<ReportStateFlattened>;
  widgetReadyEvent$ = new Subject<number>();

  isTableWidget = isTableWidget;
  isResultTableWidget = isResultTableWidget;
  isSequenceLogoWidget = isSequenceLogoWidget;
  isGraphWidget = isGraphWidget;
  isImageWidget = isImageWidget;

  private subscriptions = new Subscription();

  constructor(
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private reportStateFacade: ReportStateFacade,
    private documentHttpService: DocumentHttpV2Service,
  ) {}

  ngOnInit() {
    const pdfDocumentID = this.route.snapshot.queryParamMap.get('pdfDocumentID');

    if (pdfDocumentID) {
      this.reportState$ = this.documentHttpService
        .getDocumentPartWithHeaders<ReportJson>(pdfDocumentID, 'REPORT_JSON')
        .pipe(
          map(migrateReportJSON),
          switchMap((reportState) => {
            this.reportStateFacade.hydrateStore(reportState);
            return this.reportStateFacade.getReportState();
          }),
          first(),
          tap(() => {
            // Make sure that PDF Report generator can create several pages.
            document.body.style.height = 'auto';
            this.isPdfView = true;
            this.cd.markForCheck();
            console.log('Report PDF Generation INIT');
          }),
          share(),
        );
    } else {
      this.reportState$ = this.reportStateFacade.getReportState();
    }

    this.subscriptions.add(
      this.reportState$
        .pipe(
          tap((reportState) =>
            console.log(
              'Generating Widgets: ' + reportState.widgets.map((widget) => widget.type).join(', '),
            ),
          ),
          map((reportState) => reportState.widgets.length),
          distinctUntilChanged(),
          switchMap((numberOfWidgets) =>
            this.widgetReadyEvent$.pipe(
              scan((acc) => ++acc, 0),
              tap((numberOfWidgetsReady) => {
                console.log('# Of Widget/s Ready: ', numberOfWidgetsReady);
                console.log(`Waiting on ${numberOfWidgets - numberOfWidgetsReady} Widget/s`);
              }),
              filter((numberOfWidgetsReady) => numberOfWidgetsReady === numberOfWidgets),
              mapTo(true),
            ),
          ),
        )
        .subscribe(() => {
          // IMPORTANT: This console info log is used for the reporting pipeline to determine whether the UI is ready for
          // saving a snapshot as a PDF. DO NOT REMOVE.
          // eslint-disable-next-line no-console
          console.info('Report Ready');
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.widgetReadyEvent$.complete();
  }

  trackByID(index: number, widget: ReportWidget): string {
    return widget.id;
  }

  removeWidget(id: string) {
    this.reportStateFacade.removeWidget(id);
  }

  onTitleChanged(title: string) {
    this.reportStateFacade.updateTitle(title);
  }

  onNotesChanged(notes: string) {
    this.reportStateFacade.updateNotes(notes);
  }
}
