<button mat-icon-button class="widget-remover" (click)="removeWidget()">
  <mat-icon>cancel</mat-icon>
</button>
<bx-report-widget-title
  [widgetID]="widget.id"
  [title]="widget.title"
  [notes]="widget.notes"
  [showPlaceholders]="showControls"
>
</bx-report-widget-title>
@if (datasource$ | async; as datasource) {
  <bx-chart-presenter
    class="w-100"
    [datasource]="datasource"
    [hideControls]="!showControls"
    [hideTitle]="true"
    [animations]="showControls"
    (optionsChanged)="setChartOptions($event)"
    (finishedLoading)="widgetReady.emit()"
  >
  </bx-chart-presenter>
}
