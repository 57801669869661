import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ReportWidgetComponent, TableWidget } from '../../report.model';
import { FormatterService } from '../../../../shared/formatter.service';

import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { ReportWidgetTitleComponent } from '../../report-widget-title/report-widget-title.component';

@Component({
  selector: 'bx-table-widget',
  templateUrl: './table-widget.component.html',
  styleUrls: ['./table-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatLegacyButtonModule, MatIconModule, ReportWidgetTitleComponent],
})
export class TableWidgetComponent implements OnInit, ReportWidgetComponent {
  @Input() showControls = true;
  @Input() widget: TableWidget;
  @Output() widgetReady = new EventEmitter();
  @Output() widgetRemoved = new EventEmitter<string>();

  columnAlign: ('right' | 'left')[] = [];

  constructor() {}

  ngOnInit() {
    this.columnAlign =
      this.widget &&
      this.widget.data.body.rows.length > 0 &&
      this.widget.data.body.rows[0].map((value) => {
        return FormatterService.isNumeric(value) ? 'right' : 'left';
      });
    this.widgetReady.emit();
  }

  removeWidget() {
    this.widgetRemoved.emit(this.widget.id);
  }
}
