import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { ReportWidget } from '../report.model';
import { ReportState } from './report-state';
import {
  ActionsUnion,
  addWidget,
  addWidgets,
  clearReport,
  hydrate,
  newReport,
  removeWidget,
  updateCreatedBy,
  updateDate,
  updateTitle,
  updateNotes,
  updateWidget,
  updateWidgetTitle,
  updateWidgetNotes,
  patchWidgetData,
} from './report-state.actions';
import { UpdateStr } from '@ngrx/entity/src/models';

export const adapter: EntityAdapter<ReportWidget> = createEntityAdapter<ReportWidget>({
  sortComparer: false,
});

export const initialState: ReportState = {
  date: new Date(),
  widgets: adapter.getInitialState(),
};

export function reportStateReducer(
  state: ReportState = initialState,
  action: ActionsUnion,
): ReportState {
  switch (action.type) {
    case hydrate.type: {
      return {
        ...action.payload.reportState,
        widgets: adapter.setAll(action.payload.reportState.widgets, state.widgets),
      };
    }

    case newReport.type: {
      return {
        title: action.payload.title,
        date: action.payload.date,
        createdBy: action.payload.createdBy,
        widgets: adapter.getInitialState(),
      };
    }

    case clearReport.type: {
      return initialState;
    }

    case updateTitle.type: {
      return {
        ...state,
        title: action.payload.title,
      };
    }

    case updateNotes.type: {
      return {
        ...state,
        notes: action.payload.notes,
      };
    }

    case updateDate.type: {
      return {
        ...state,
        date: action.payload.date,
      };
    }

    case updateCreatedBy.type: {
      return {
        ...state,
        createdBy: action.payload.createdBy,
      };
    }

    case addWidget.type: {
      return {
        ...state,
        widgets: adapter.addOne(action.payload.widget, state.widgets),
      };
    }

    case addWidgets.type: {
      return {
        ...state,
        widgets: adapter.addMany(action.payload.widgets, state.widgets),
      };
    }

    case updateWidget.type: {
      return {
        ...state,
        widgets: adapter.updateOne(
          JSON.parse(JSON.stringify(action.payload.widget)),
          state.widgets,
        ),
      };
    }

    case updateWidgetTitle.type: {
      const update: UpdateStr<ReportWidget> = {
        id: action.payload.id,
        changes: {
          title: action.payload.title,
        },
      };
      return {
        ...state,
        widgets: adapter.updateOne(update, state.widgets),
      };
    }

    case updateWidgetNotes.type: {
      const update: UpdateStr<ReportWidget> = {
        id: action.payload.id,
        changes: {
          notes: action.payload.notes,
        },
      };
      return {
        ...state,
        widgets: adapter.updateOne(update, state.widgets),
      };
    }

    case patchWidgetData.type: {
      const { id, data } = action.payload;
      const widget = state.widgets.entities[id];
      return {
        ...state,
        widgets: adapter.updateOne(
          {
            id,
            changes: {
              data: { ...widget?.data, ...data },
            } as Partial<ReportWidget>,
          },
          state.widgets,
        ),
      };
    }

    case removeWidget.type: {
      return {
        ...state,
        widgets: adapter.removeOne(action.payload.id, state.widgets),
      };
    }

    default:
      return state;
  }
}
