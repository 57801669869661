import { v4 as UUID } from 'uuid';
import { isReportState, ReportJson, ReportWidget } from './report.model';
import { ReportStateFlattened } from './report-store/report-state';

/**
 * Migrates old Report Json into the new ReportStateFlattened format.
 * This migration should be removed once the old report json is permanently migrated on the backend.
 */
export function migrateReportJSON(json: ReportJson): ReportStateFlattened {
  if (isReportState(json)) {
    return {
      ...json,
      date: new Date(json.date),
    };
  } else {
    return {
      title: `Report of: ${json.documentName}`,
      date: new Date(json.createdDate),
      createdBy: json.username,
      widgets: migrateWidgetsV1(json.widgets),
    };
  }
}

/**
 * Migrate old Widget data.
 * `title` is now part of the root Widget object instead of deep in the data property.
 * Graph Widgets now have a title. So the `name` property needs to be migrated to the root `title` property.
 *
 */
function migrateWidgetsV1(widgets: any[]): ReportWidget[] {
  return widgets.map((widget) => ({
    id: UUID(),
    ...widget,
    title: widget.data.title || widget.data.name,
    data: migrateWidgetData(widget.data),
  }));
}

function migrateWidgetData(data: any) {
  if (data.title) {
    const { title: _, ...remaining } = data;
    return remaining;
  } else if (data.name) {
    const { name: _, ...remaining } = data;
    return remaining;
  } else {
    return data;
  }
}
