import { ScaleOrdinal, ScaleSequential } from 'd3';
import { ColorPaletteID } from 'src/app/core/color/color-palette.model';
import { Point2D } from 'src/app/shared/interfaces';

export type D3Selection<T extends d3.BaseType> = d3.Selection<T, undefined, null, undefined>;

export type TreeGraphMetadataValue = string | number | null;
export type TreeGraphMetadataMapValue = {
  group: TreeGraphMetadataValue;
  value: TreeGraphMetadataValue;
};
export type TreeGraphMetadataMap = Map<string, TreeGraphMetadataMapValue>;

/** Config for coloring and displaying values from a metadata field */
export type TreeGraphColoringMetadataConfig = {
  /** The name of the metadata field */
  name: string;
  /** The original of the metadata field */
  metadataField: string;
  /**
   * A map from node ID to an object representing the group for coloring purposes and the raw value.
   * For gene metadata, the group will be the most common gene family, and the value will be the full
   * gene information. Most of the time, the group and the value will be equal.
   */
  values: Map<string, TreeGraphMetadataMapValue>;
  /** The palette to use to represent this metadata field */
  colorPalette: ColorPaletteID;
  /** Whether this metadata contains categorical data. If false, it is assumed to be numeric. */
  isCategorical: boolean;
  /** The number of unique values for this metadata field. Can be null if the data is not categorical. */
  numGroups: number | null;
};

export type TreeGraphColoringHeatmapConfig = TreeGraphColoringMetadataConfig & {
  /** Indicate whether the config is collapsed in the UI */
  collapsed: boolean;
};

/** Config for the labels that are shown on the tree's leaves */
export type TreeGraphTipLabelConfig = {
  /** Whether tip labels should be shown */
  show: boolean;
  /** The values for the tip labels */
  labels: Map<string, string>;
  /** The maximum number of characters a tip label can contain before being truncated. */
  maxChars: number;
  /** The friendly name of the metadata field that is being used for tip labels */
  name: string;
};

export type TreeGraphDefaultTooltips = Map<string, Record<string, string>>;

export type TreeGraphHeatmapConfig = {
  show: boolean;
  heatmapRowConfigs: TreeGraphColoringMetadataConfig[];
};

export type TreeGraphLegendConfig = {
  show: boolean;
  /** Nullable when there's no valid legend to be shown */
  metadataColoringConfig?: TreeGraphColoringMetadataConfig;
};

/**
 * A linear interval, start-inclusive and end-exclusive. The start value must
 * be less or equal to end.
 */
interface Range {
  start: number;
  end: number;
}

export interface Dimensions {
  width: number;
  height: number;
}

export interface LabelPositionData {
  /** The ID from TreeGraphData */
  id: string;
  /** The position of the left-middle of the label in polar co-ordinate space */
  polarCoord: {
    /** Angle in radians from the reference direction (which points to the right) */
    direction: number;
    /** Distance from the reference point (the origin) in pixels */
    distance: number;
  };
  /** Half the label's height in pixels */
  labelHeightDelta: number;
  /** The range that the label occupies on the distance axis */
  rayRange: Range;
}

export interface GraphCircularTreeSharedConfig {
  /** The font size for labels */
  readonly labelFontSize: number;
  /** Internal padding */
  readonly padding: number;
}

/** Static configuration values for the circular tree's legend. */
export interface CategoricalDataLegendConfig extends GraphCircularTreeSharedConfig {
  /** Maximum number of labels to display on the legend */
  readonly maxGroups: number;
  /** Maximum length of label text (in characters) */
  readonly maxLabelLength: number;
  /** Vertical space between lines of text */
  readonly ySpacing: number;
  /** Radius of the colored circles */
  readonly tipRadius: number;
}

export interface ContinuousDataLegendConfig extends GraphCircularTreeSharedConfig {
  /** The width of the color key (rectangle containing a gradient of the color palette's range) */
  readonly colorKeyWidth: number;
  /** The height of the color key (rectangle containing a gradient of the color palette's range) */
  readonly colorKeyHeight: number;
  /** The maximum number of ticks to put on the key */
  readonly numTicks: number;
  /** How far the tick line extends from the color key */
  readonly tickSize: number;
  /** The font size to use for tick labels */
  readonly tickLabelFontSize: FontSize;
}

/** Static configuration values for adjusting the appearance of the circular tree. */
export interface GraphCircularTreeConfig extends GraphCircularTreeSharedConfig {
  /** The gap between the tree and the legend */
  readonly legendLeftMargin: number;
  /** The font weight of the legend title */
  readonly legendTitleFontWeight: string;
  /** The font size of the legend title */
  readonly legendTitleFontSize: FontSize;
  /** Config for the categorical legend */
  readonly categoricalLegend: CategoricalDataLegendConfig;
  /** Config for the continuous legend */
  readonly continuousLegend: ContinuousDataLegendConfig;
}

/**
 * Returns default values for the circular tree configuration.
 */
export function defaultGraphCircularTreeConfig(): GraphCircularTreeConfig {
  const sharedConfig = {
    labelFontSize: 14,
    padding: 15,
  };
  return {
    ...sharedConfig,
    legendLeftMargin: 10,
    legendTitleFontWeight: '700',
    legendTitleFontSize: '0.875rem',
    categoricalLegend: {
      ...sharedConfig,
      maxGroups: 20,
      maxLabelLength: 15,
      ySpacing: 5,
      tipRadius: 5,
    },
    continuousLegend: {
      ...sharedConfig,
      colorKeyWidth: 20,
      colorKeyHeight: 300,
      numTicks: 6,
      tickSize: 6,
      tickLabelFontSize: '12px',
    },
  };
}

export type CategoricalColorFn = ScaleOrdinal<string | number, string, string>;
export type SequentialColorFn = ScaleSequential<string, string>;

/**
 * A function that accepts a value and returns a color from the selected palette.
 */
export type D3ColorFunction = CategoricalColorFn | SequentialColorFn;

export function isSequentialColorFn(
  colorFn: D3ColorFunction | null | undefined,
): colorFn is ScaleSequential<string, string> {
  return colorFn != null && 'interpolator' in colorFn;
}

export type LegendType = 'categorical' | 'continuous';
export type FontSize<T extends 'px' | 'em' | 'rem' = 'px' | 'em' | 'rem'> = `${number}${T}`;
/** The interface shared by legend content implementations */
export interface GraphCircularTreeLegendContent {
  /** The type of legend that this class renders */
  readonly legendType: LegendType;
  /** The element that legend content should be added to */
  readonly parent: D3Selection<SVGGElement>;

  /**
   * Renders the legend content for the provided values and color scheme.
   *
   * @param values the set of unique values
   * @param colorFn a Scale that returns a color for each value
   * @param origin the top-left point to start drawing from
   * @returns the dimensions of the rendered content
   */
  render(values: TreeGraphMetadataValue[], colorFn: D3ColorFunction, origin: Point2D): Dimensions;
}
