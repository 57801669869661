import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ZoomableChart } from './graph-zoom.component';

@Injectable({
  providedIn: 'root',
})
export class NgsZoomService {
  constructor() {}

  private zoomControls$: BehaviorSubject<ZoomableChart> = new BehaviorSubject(null);

  public registerZoomControls(chart: ZoomableChart) {
    this.zoomControls$.next(chart);
  }

  public deregisterZoomControls() {
    this.zoomControls$.next(null);
  }

  public getZoomControls() {
    return this.zoomControls$.asObservable();
  }
}
