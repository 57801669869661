<div ngbDropdown class="d-inline-block" (openChange)="onDropdownChange($event)">
  <button
    type="button"
    title="Select color palette"
    class="btn btn-light d-flex align-items-center"
    id="select-color-palette"
    ngbDropdownToggle
    [attr.disabled]="disabled$ | async"
    data-spec="dropdown-toggle"
  >
    @if ({ palette: selectedPalette$ | async }; as selected) {
      <bx-color-palette
        [palette]="selected.palette?.id"
        [numSwatches]="numSwatches"
        [title]="selected.palette?.name ?? 'No palette selected'"
        class="p-0"
        data-spec="selected-palette"
      ></bx-color-palette>
    }
  </button>
  <div ngbDropdownMenu aria-labelledby="select-color-palette" data-spec="dropdown-menu">
    @for (palette of palettes$ | async; track palette.id) {
      <button
        ngbDropdownItem
        [title]="palette.name"
        (click)="writeValue(palette.id)"
        class="btn btn-light px-3 py-1"
        data-spec="palette-option"
      >
        <bx-color-palette
          [palette]="palette.id"
          [numSwatches]="numSwatches"
          class="p-0"
        ></bx-color-palette>
      </button>
    }
  </div>
</div>
