// @ts-ignore
import { Alphabets, SequenceColors } from '@geneious/shared-constants';
import { ColorScheme, ColorSchemeCollection } from '@geneious/shared-constants/types';
import {
  GraphControlTypeEnum,
  GraphSidebarCheckboxControl,
  GraphSidebarControls,
  GraphSidebarSelectControl,
} from '../graph-sidebar';
import { SeriesColumnOptions } from 'highcharts';

export class StackedColumnColorSchemeHandler {
  private _colorSchemes: any = {
    Nucleotide: {
      Default: {
        defaultColor: '#646464',
        colors: {
          a: '#bb82cd',
          c: '#d04750',
          g: '#d28826',
          t: '#5888e0',
          '?': '#a5358e',
        },
      },
    },
    AminoAcid: {
      Default: {
        defaultColor: '#646464',
        colors: {
          h: '#c93c73',
          j: '#b8505e',
          c: '#d04750',
          d: '#7f2e1c',
          n: '#df7f61',
          e: '#ba4f2d',
          f: '#c6702d',
          g: '#d28826',
          i: '#99702f',
          m: '#d9b15d',
          k: '#c8aa34',
          p: '#8c923c',
          q: '#93b641',
          l: '#5ba756',
          r: '#45c097',
          s: '#66a1e5',
          t: '#5888e0',
          v: '#5b7fee',
          w: '#6068b2',
          y: '#5855ba',
          u: '#a981e3',
          o: '#4d2a86',
          b: '#503277',
          z: '#9950b3',
          a: '#bb82cd',
          x: '#df76d6',
          '?': '#a5358e',
          '*': '#d375b9',
        },
      },
    },
  };

  private readonly INDEL_COLOR = '#E5E5E5';

  constructor(
    public sequenceType: string,
    public colorSchemeName: string = 'Default',
  ) {
    this.addSharedColorSchemes(SequenceColors.Nucleotide, this._colorSchemes.Nucleotide);
    this.addSharedColorSchemes(SequenceColors.AminoAcid, this._colorSchemes.AminoAcid);
  }

  getStyleControls(options: { showLabels: boolean; showLegend: boolean }): GraphSidebarControls {
    return [
      this.getColorControl(),
      this.getLabelsControl(options.showLabels),
      this.getLegendControl(options.showLegend),
    ];
  }

  getColorControl(): GraphSidebarSelectControl {
    return {
      name: 'colorScheme',
      label: 'Color',
      type: GraphControlTypeEnum.SELECT,
      defaultOption: this.colorSchemeName,
      options: this.colorSchemeNames.map((name) => {
        return {
          displayName: name,
          value: name,
        };
      }),
    };
  }

  getLabelsControl(showLabels?: boolean): GraphSidebarCheckboxControl {
    return {
      name: 'showLabels',
      label: 'Show Labels',
      type: GraphControlTypeEnum.CHECKBOX,
      defaultOption: showLabels,
    };
  }

  getLegendControl(showLegend?: boolean): GraphSidebarCheckboxControl {
    return {
      name: 'showLegend',
      label: 'Show Legend',
      type: GraphControlTypeEnum.CHECKBOX,
      defaultOption: showLegend,
    };
  }

  setColors(data: SeriesColumnOptions[]) {
    return data.map((options) => {
      return {
        ...options,
        color: this.backgroundColor(options.name),
      };
    });
  }

  private backgroundColor(residue: string) {
    return this.colorScheme.colors[residue.toLowerCase()] || this.colorScheme.defaultColor;
  }

  get colorSchemes() {
    return this._colorSchemes[this.sequenceType];
  }

  get colorSchemeNames(): string[] {
    if (this.sequenceType) {
      return Object.keys(this.colorSchemes);
    }
  }

  get colorScheme() {
    if (!this.colorSchemes[this.colorSchemeName]) {
      this.colorSchemeName = 'Default';
    }

    return this.colorSchemes[this.colorSchemeName];
  }

  private addSharedColorSchemes(shared: ColorSchemeCollection, local: any) {
    Object.values(shared)
      .filter((scheme) => !scheme.isForeground && !scheme.isDynamic)
      .forEach((scheme) => {
        local[scheme.normalName] = this.newSchemeFromSharedColorSchemes(scheme);
      });
  }

  private newSchemeFromSharedColorSchemes({ defaultColor, colors }: ColorScheme) {
    const scheme: any = {
      defaultColor: this.convertWhiteToGrey(defaultColor),
      colors: {},
    };

    Object.entries(colors).forEach(([k, color]) => {
      scheme.colors[k] = this.convertWhiteToGrey(color);
    });

    scheme.colors['-'] = this.INDEL_COLOR;

    return scheme;
  }

  private convertWhiteToGrey(color: string) {
    return color === '#FFFFFF' ? '#646464' : color;
  }
}
