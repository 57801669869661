import * as d3 from 'd3';
import { D3GraphCanvas } from '../../d3-graphs-shared/d3-graph-canvas';
import {
  D3ColorFunction,
  D3Selection,
  Dimensions,
  GraphCircularTreeConfig,
  GraphCircularTreeLegendContent,
  LegendType,
  TreeGraphMetadataValue,
} from '../graph-circular-tree.model';
import { CategoricalDataLegendContent } from './categorical-data-legend';
import { ContinuousDataLegendContent } from './continuous-data-legend';

/**
 * Creates and updates the Legend SVG that is displayed next to the Circular Tree Graph.
 * This class creates the container SVG and the legend title, and then delegates drawing
 * the legend content to the appropriate class depending on the legend type. It also
 * handles showing and hiding the legend element.
 */
export class GraphCircularTreeLegend {
  private readonly svg: D3Selection<SVGSVGElement>;
  private readonly title: D3Selection<SVGTextElement>;
  private readonly contentContainer: D3Selection<SVGGElement>;
  private content: GraphCircularTreeLegendContent;
  private _visible = true;

  constructor(
    private readonly config: GraphCircularTreeConfig,
    private readonly canvas: D3GraphCanvas,
    initialLegendType: LegendType = 'categorical',
  ) {
    this.svg = d3
      .create('svg')
      .attr('font-family', 'sans-serif')
      .attr('font-size', this.config.labelFontSize)
      .attr('width', 100)
      .attr('display', this._visible ? 'block' : 'none')
      .attr(
        'style',
        `margin-left: ${this.config.legendLeftMargin}px; overflow: visible; position: absolute; flex: 0 0 auto; right: 20;`,
      );
    // Add background
    this.svg
      .append('rect')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('stroke', '#a8afb5')
      .attr('opacity', 0.8)
      .attr('fill', 'white');

    const titleAscent = this.canvas.measureText('T', {
      fontWeight: this.config.legendTitleFontWeight,
      fontSize: this.config.legendTitleFontSize,
    }).actualBoundingBoxAscent;

    this.title = this.svg
      .append('text')
      .attr('x', this.config.padding)
      .attr('y', this.config.padding + titleAscent)
      .attr('font-weight', this.config.legendTitleFontWeight)
      .attr('font-size', this.config.legendTitleFontSize);

    this.contentContainer = this.svg.append('g');

    this.setLegendType(initialLegendType);
  }

  /**
   * Renders the Legend SVG for the provided values.
   *
   * @param title the legend title (name of the metadata property)
   * @param values unique values to display on the legend (metadata values)
   * @param colorFn a function that provides a color for each metadata value
   * @returns the dimensions of the legend SVG
   */
  render(title: string, values: TreeGraphMetadataValue[], colorFn: D3ColorFunction): Dimensions {
    const titleDimensions = this.renderTitle(title);
    const contentOrigin = {
      x: this.config.padding,
      y: this.config.padding * 2 + titleDimensions.height,
    };
    const contentDimensions = this.content.render(values, colorFn, contentOrigin);
    const height = contentOrigin.y + contentDimensions.height + this.config.padding;
    const width =
      this.config.padding * 2 + Math.max(contentDimensions.width, titleDimensions.width);
    this.svg.attr('height', height).attr('width', width);
    return { width, height };
  }

  private renderTitle(title: string): { width: number; height: number } {
    this.title.text(title);
    const textMetrics = this.canvas.measureText(title, {
      fontWeight: this.config.legendTitleFontWeight,
      fontSize: this.config.legendTitleFontSize,
    });
    return {
      width: textMetrics.width,
      height: textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent,
    };
  }

  /**
   * Sets the type of legend to display
   *
   * @param legendType the type of legend to display
   */
  setLegendType(legendType: 'categorical' | 'continuous'): void {
    if (this.content?.legendType === legendType) {
      return;
    }
    this.contentContainer.selectAll('*').remove();
    if (legendType === 'categorical') {
      this.content = new CategoricalDataLegendContent(
        this.contentContainer,
        this.config.categoricalLegend,
        this.canvas,
      );
      return;
    }
    if (legendType === 'continuous') {
      this.content = new ContinuousDataLegendContent(
        this.contentContainer,
        this.config.continuousLegend,
      );
    }
  }

  /** The visibility of the legend. */
  get visible(): boolean {
    return this._visible;
  }

  set visible(visible: boolean) {
    if (this._visible == visible) {
      return;
    }
    this._visible = visible;
    this.svg.style('display', visible ? 'block' : 'none');
  }

  /** The HTML element for the legend SVG */
  get nativeElement(): SVGSVGElement {
    return this.svg.node();
  }

  /**
   * The width of the legend SVG, including the left margin. Returns zero if the
   * legend is not visible.
   */
  get width(): number {
    if (this._visible) {
      return parseInt(this.svg.attr('width')) + this.config.legendLeftMargin;
    }
    return 0;
  }
}
