import {
  GraphControlTypeEnum,
  GraphDatasource,
  GraphDatasourceError,
  GraphSidebarControls,
  GraphSidebarDatasourceResponse,
  GraphTypeEnum,
} from '../graph-sidebar';
import {
  AbaAllChartOptions,
  ClusterLengths,
  GraphAbaDataService,
  GraphOption,
} from '../graph-aba-data.service';
import { SummaryGraphParams } from '../../../core/ngs/ngs-summary-graph-viewer/ngs-summary-graph-viewer.component';
import { IStackedBarInfo } from '../stacked-column-chart/stacked-bar-info.model';
import { StackedBarChartOptions } from '../../report/report.model';
import { StackedColumnColorSchemeHandler } from './stacked-column-colorscheme-handler';
import { isExactClusterTable } from '../../../core/ngs/table-type-filters';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import { hasClusterLengthColumn } from '../../../core/ngs/table-column-filters';
import { firstValueFrom } from 'rxjs';

export default class AbaAminoAcidDistributionGraphDatasource implements GraphDatasource {
  private readonly regions: GraphOption[];
  private data: IStackedBarInfo;
  private clusterLengths: ClusterLengths;
  private colorSchemeHandler: StackedColumnColorSchemeHandler;

  constructor(
    private dataService: GraphAbaDataService,
    private readonly documentID: string,
    private tables: Record<string, DocumentTable>,
    private params: SummaryGraphParams,
    private allOptions: AbaAllChartOptions,
    private initialOptions?: StackedBarChartOptions,
  ) {
    this.colorSchemeHandler = new StackedColumnColorSchemeHandler(
      'AminoAcid',
      initialOptions?.colorScheme,
    );
    this.regions = this.allOptions.regions.filter(
      (region) => isExactClusterTable(region) && hasClusterLengthColumn(tables[region.table]),
    );
  }

  validate(): GraphDatasourceError | null {
    if (!(this.initialOptions?.region ?? this.regions[0])?.name) {
      return {
        error:
          "No region clusters present. Your sequence filtering options may have been too restrictive for this data set. Please re-run analysis without the 'Only cluster' options turned on.",
        controls: [],
      };
    }
    return null;
  }

  init(): Promise<GraphSidebarDatasourceResponse> {
    const validation = this.validate();
    if (validation) {
      return Promise.resolve(validation);
    }
    const options = {
      region: (this.initialOptions?.region ?? this.regions[0]).name,
      length: this.initialOptions?.length,
      colorScheme: this.initialOptions?.colorScheme ?? 'Default',
      showLabels: this.initialOptions?.showLabels ?? true,
      showLegend: this.initialOptions?.showLegend ?? true,
    };
    return this.getAminoAcidDistributionData(options, options.region);
  }

  controlValueChanged(
    previousOptions: SidebarOptions<string>,
    options: SidebarOptions<string>,
  ): Promise<GraphSidebarDatasourceResponse> {
    this.colorSchemeHandler.colorSchemeName = options.colorScheme;
    if (previousOptions.region !== options.region || previousOptions.length !== options.length) {
      return this.getAminoAcidDistributionData(options, previousOptions.region);
    } else {
      const region = this.regions.find((r) => r.name === options.region);
      const currentOptions = {
        ...options,
        region: region,
        length: options.length,
      };
      return Promise.resolve(
        this.formatData(this.data, currentOptions, this.clusterLengths, false),
      );
    }
  }

  private async getAminoAcidDistributionData(
    options: SidebarOptions<string>,
    previousRegion?: string,
  ): Promise<GraphSidebarDatasourceResponse> {
    const region = this.regions.find((r) => r.name === options?.region);
    if (!this.clusterLengths || options.region !== previousRegion) {
      this.clusterLengths = await firstValueFrom(
        this.dataService.getFormattedClusterLengths(this.params.source, this.documentID, region),
      );
    }
    const length =
      options.region === previousRegion && options.length
        ? options.length
        : Number(this.clusterLengths[0]);

    this.data = await firstValueFrom(
      this.dataService.getStackedBarChartData(this.params, this.documentID, {
        region,
        length,
      }),
    );

    const currentOptions = {
      ...options,
      region,
      length,
    };
    return this.formatData(this.data, currentOptions, this.clusterLengths);
  }

  private formatData(
    chart: IStackedBarInfo,
    options: SidebarOptions<GraphOption>,
    lengths: ClusterLengths,
    animations = true,
  ): GraphSidebarDatasourceResponse {
    const controls = this.generateControls(options, lengths);
    if (chart?.data?.length === 0) {
      return {
        error: `No data available for ${options.region.name}`,
        controls,
      };
    }

    return {
      graph: {
        data: this.colorSchemeHandler.setColors(chart.data),
        title: chart.title,
        xAxisTitle: 'Position',
        yAxisTitle: 'Frequency',
        stacking: 'percent',
        showLabels: options.showLabels,
        showLegend: options.showLegend,
        animations: animations,
        type: GraphTypeEnum.STACKED_COLUMN,
      },
      controls,
      options,
    };
  }

  private generateControls(
    options: SidebarOptions<GraphOption>,
    lengths: ClusterLengths,
  ): GraphSidebarControls {
    return [
      {
        name: 'region',
        label: 'Region',
        type: GraphControlTypeEnum.SELECT,
        defaultOption: options.region.name,
        options: this.regions.map(({ name }) => {
          return {
            displayName: name,
            value: name,
          };
        }),
      },
      {
        name: 'length',
        label: 'Length',
        type: GraphControlTypeEnum.SELECT,
        defaultOption: options.length,
        options: lengths.map((length) => {
          return {
            displayName: `${length}`,
            value: Number(length),
          };
        }),
      },
      ...this.colorSchemeHandler.getStyleControls(options),
    ];
  }
}

interface SidebarOptions<T extends string | GraphOption> {
  region: T;
  length: number;
  colorScheme: string;
  showLabels: boolean;
  showLegend: boolean;
}
