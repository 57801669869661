import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

/**
 * Used primarily by the ResultTableWidgetComponent for showing a read-only header for each column
 * to avoid the user trying to click the header and sort the table.
 */
@Component({
  selector: 'bx-header-readonly',
  templateUrl: './read-only-header-component.html',
  standalone: true,
})
export class ReadOnlyHeaderComponent implements IHeaderAngularComp {
  displayName: string;

  agInit({ displayName }: IHeaderParams<{ displayName: string }>): void {
    this.displayName = displayName;
  }

  refresh({ displayName }: IHeaderParams<{ displayName: string }>): boolean {
    this.displayName = displayName;
    return true;
  }
}
