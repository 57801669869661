import { Injectable } from '@angular/core';
import { max, min, scaleOrdinal, scaleSequential } from 'd3';
import { D3ColorFunction } from 'src/app/features/graphs/graph-circular-tree/graph-circular-tree.model';
import {
  ColorPalette,
  ColorPaletteID,
  ColorPaletteKind,
  ColorPalettes,
  PaletteFilter,
} from './color-palette.model';

@Injectable({
  providedIn: 'root',
})
export class ColorPaletteService {
  /**
   * Fetches colors from the specified palette.
   *
   * @param paletteID the ID of the palette
   * @param length the maximum number of colors to return. Categorical palettes may return less.
   * @returns a list of colors from the specified palette
   */
  getSwatches(paletteID: ColorPaletteID, length: number): string[] {
    const palette = this.getPalette(paletteID);
    if (palette.kind === ColorPaletteKind.CATEGORICAL) {
      return palette.colors.slice(0, length);
    }
    const colorFn = scaleSequential([0, length - 1], palette.interpolator);
    return Array.from({ length }, (_, i) => colorFn(i));
  }

  /**
   * Returns the color palette with the specified ID, or throws an error if it doesn't exist.
   *
   * @param paletteID the ID of the palette
   * @returns the color palette object
   */
  getPalette(paletteID: ColorPaletteID): ColorPalette {
    const palette = ColorPalette[paletteID];
    if (palette == null) {
      throw new Error(`There is no color palette with the ID "${paletteID}"`);
    }
    return palette;
  }

  /**
   * Gets palettes that meet the filter criteria.
   *
   * @param filter the criteria that palettes must match
   * @returns an array of palettes
   */
  getPalettes(filter: PaletteFilter = {}): ColorPalette[] {
    const criteria: ((palette: ColorPalette) => boolean)[] = [];
    if (filter.kind) {
      criteria.push((palette) => palette.kind === filter.kind);
    }
    if (filter.numCategories) {
      criteria.push((palette) => palette.maxCategories >= filter.numCategories);
    }
    return ColorPalettes.filter((palette) => criteria.every((criterion) => criterion(palette)));
  }

  /**
   * Returns the palette ID if it meets the specified criteria. Otherwise, returns the ID of a
   * palette that does meet the criteria.
   *
   * @param paletteID the ID of the desired palette
   * @param filter the criteria that the returned palette must match
   * @returns a valid palette for the criteria
   */
  validPalette(paletteID: ColorPaletteID, filter: PaletteFilter): ColorPaletteID {
    const validPalettes = this.getPalettes(filter);
    if (validPalettes.some((palette) => palette.id === paletteID)) {
      return paletteID;
    }
    return validPalettes[0].id ?? 'rainbow';
  }

  /**
   * Returns a function that returns a color from the specified palette when passed a value.
   *
   * @param paletteID the ID of the palette
   * @param values the values that will be passed to the color function
   * @param isCategorical whether the values are categorical (or otherwise continuous)
   * @param unknownValueColor the color assigned to null or unknown values
   * @returns a function that returns a color when passed a value
   */
  getColorFunction(
    paletteID: ColorPaletteID,
    values: (string | number | null)[],
    isCategorical: boolean,
    unknownValueColor = '#000',
  ): D3ColorFunction {
    const filteredValues = new Set([...values].sort());
    filteredValues.delete(null);

    const palette = this.getPalette(paletteID);
    if (palette.kind === ColorPaletteKind.CATEGORICAL) {
      return scaleOrdinal(filteredValues, palette.colors).unknown(unknownValueColor);
    }

    if (isCategorical) {
      const scale = scaleSequential([0, filteredValues.size - 1], palette.interpolator);
      const domain = [...filteredValues];
      return scaleOrdinal(
        domain,
        domain.map((_value, i) => scale(i)),
      ).unknown(unknownValueColor);
    }
    const domain = [min(filteredValues as Set<number>), max(filteredValues as Set<number>)];
    return scaleSequential(domain, palette.interpolator).unknown(unknownValueColor);
  }
}
