<div
  #graph
  id="zoom-container"
  tabindex="-1"
  class="w-100 h-100 flex-grow-1 flex-shrink-1 d-flex align-items-center justify-content-between position-relative min-w-0"
>
  <div
    id="scroll-bar-y"
    class="h-100 overflow-auto position-absolute"
    style="top: 0; right: 0; z-index: 10"
  >
    <div id="scroll-content-y" style="width: 20px; height: 1000px"></div>
  </div>
  <div
    id="scroll-bar-x"
    class="w-100 overflow-auto position-absolute"
    style="bottom: 0; left: 0; z-index: 10"
  >
    <div id="scroll-content-x" style="height: 25px; width: 1000px"></div>
  </div>
</div>
