import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReportState } from './report-state';
import { adapter } from './report-state.reducer';

export const { selectAll, selectEntities } = adapter.getSelectors();

export const selectReportState = createFeatureSelector<ReportState>('reportState');

export const selectReportStateFlattened = createSelector(selectReportState, (state) => ({
  ...state,
  widgets: selectAll(state.widgets),
}));

const selectReportWidgets = createSelector(selectReportState, (state) => state.widgets);

export const selectAllReportWidgets = createSelector(selectReportWidgets, selectAll);

export const selectReportWidget = (id: string) =>
  createSelector(selectReportWidgets, (widgets) => widgets.entities[id]);
