<div class="d-flex flex-column">
  <h4
    [bx-editable-text]="title"
    [placeholder]="titlePlaceholder"
    (editableTextChanged)="titleChanged$.next($event)"
  ></h4>
  <p
    [bx-editable-text]="notes"
    [placeholder]="notesPlaceholder"
    (editableTextChanged)="notesChanged$.next($event)"
  ></p>
</div>
