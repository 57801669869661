<button mat-icon-button class="widget-remover" (click)="removeWidget()">
  <mat-icon>cancel</mat-icon>
</button>

@if (widget$ | async; as state) {
  <bx-report-widget-title
    [widgetID]="state.id"
    [title]="state.title"
    [notes]="state.notes"
    [showPlaceholders]="this.showControls"
  >
  </bx-report-widget-title>
}

@if (showControls) {
  <div class="mb-2">
    <select class="form-select" [formControl]="tablePreferenceProfileIDSelectionControl">
      @for (profile of tablePreferenceProfiles$ | async; track profile) {
        <option [value]="profile.id">
          {{ profile.name }}
        </option>
      }
    </select>
  </div>
  <div class="row align-items-center gx-0 mb-2">
    <div class="col-auto">
      <div class="form-check form-check-inline me-2">
        <input
          class="form-check-input"
          [formControl]="topRowsOnly"
          type="checkbox"
          [id]="'topRowsOnly_' + uniqueID"
        />
        <label class="form-check-label text-nowrap" [for]="'topRowsOnly_' + uniqueID">
          Include top
        </label>
      </div>
    </div>
    <div class="col-auto me-2">
      <input
        class="form-control"
        [formControl]="numberOfRows"
        [id]="'numberOfRows_' + uniqueID"
        type="number"
      />
    </div>
    <div class="col-auto">
      <label class="col-form-label text-nowrap" [for]="'numberOfRows_' + uniqueID">rows only</label>
    </div>
  </div>
}

@if (showMaxRowsWarningMessage$ | async) {
  <small class="text-muted d-block mb-2" data-spec="max-rows-warning">
    Previewing 10 rows. Report will only generate a maximum of 500 rows
  </small>
}

<ag-grid-angular
  class="report-grid-theme"
  [gridOptions]="gridOptions"
  [columnDefs]="columnDefinitions$ | async"
  [rowData]="rows$ | async"
  (rowDataUpdated)="onRowDataChanged()"
  (displayedColumnsChanged)="onColumnsChanged()"
>
</ag-grid-angular>
