<button
  (click)="togglePanel()"
  type="button"
  class="btn btn-light btn-sm justify-content-center h-100 p-0 border-top-0 border-bottom-0 rounded-0 bx-gutter-button"
  title="Show/Hide Options"
  data-spec="panel-toggle-btn"
>
  <div class="d-flex w-100 justify-content-center">
    <fa-icon [icon]="collapsed ? caretLeft : caretRight"></fa-icon>
  </div>
</button>

<div
  [attr.class]="panelClass"
  [ngClass]="{ panelClass, 'd-none': collapsed }"
  data-spec="panel-content"
>
  <ng-content></ng-content>
</div>
