import { EventEmitter } from '@angular/core';
import { GraphOption } from '../graphs/graph-aba-data.service';
import { GridState } from '../grid/grid.interfaces';
import { ReportStateFlattened } from './report-store/report-state';

export enum WidgetType {
  TEXT_WIDGET = 'text',
  CUSTOM_TABLE_WIDGET = 'customTable',
  RESULT_TABLE_WIDGET = 'resultTable',
  SEQUENCE_LOGO_WIDGET = 'sequence logo',
  GRAPH_WIDGET = 'graph',
  IMAGE_WIDGET = 'image',
}

export type ReportJson = LegacyReportJson | ReportStateFlattened;

export interface LegacyReportJson {
  widgets: any[];
  documentName: string;
  username: string;
  createdDate: string;
}

// Taken from this suggestion https://github.com/microsoft/TypeScript/issues/28791#issuecomment-443520161
type UnionOmit<T, K extends keyof any> = T extends T ? Pick<T, Exclude<keyof T, K>> : never;
type OmitID<T> = UnionOmit<T, 'id'>;

export type NewReportWidget = OmitID<ReportWidget>;

export interface Widget {
  readonly id: string;
  readonly title?: string;
  readonly notes?: string;
  readonly type: WidgetType;
}

export interface TableWidget extends Widget {
  readonly type: WidgetType.CUSTOM_TABLE_WIDGET;
  readonly data: CustomTableWidgetData;
}
export type NewTableWidget = OmitID<TableWidget>;

export interface ResultTableWidget extends Widget {
  readonly type: WidgetType.RESULT_TABLE_WIDGET; // Change to WidgetType
  readonly data: ResultTableWidgetData;
}
export type NewResultTableWidget = OmitID<ResultTableWidget>;

export interface TextWidget extends Widget {
  readonly type: WidgetType.TEXT_WIDGET;
  readonly content: string;
}
export type NewTextWidget = OmitID<TextWidget>;

export interface SequenceLogoWidget extends Widget {
  readonly data: SequenceLogoWidgetData;
}
export type NewSequenceLogoWidget = OmitID<SequenceLogoWidget>;

export interface GraphWidget extends Widget {
  readonly type: WidgetType.GRAPH_WIDGET;
  readonly data: GraphWidgetData;
}
export type NewGraphWidget = OmitID<GraphWidget>;

export interface ImageWidget extends Widget {
  readonly type: WidgetType.IMAGE_WIDGET;
  readonly data: ImageWidgetData;
}
export type NewImageWidget = OmitID<ImageWidget>;

export type ReportWidget =
  | TableWidget
  | ResultTableWidget
  | SequenceLogoWidget
  | ImageWidget
  | GraphWidget;

export function isTableWidget(widget: Widget): widget is TableWidget {
  return widget.type === WidgetType.CUSTOM_TABLE_WIDGET;
}

export function isResultTableWidget(widget: Widget): widget is ResultTableWidget {
  return widget.type === WidgetType.RESULT_TABLE_WIDGET;
}

export function isSequenceLogoWidget(widget: Widget): widget is SequenceLogoWidget {
  return widget.type === WidgetType.SEQUENCE_LOGO_WIDGET;
}

export function isGraphWidget(widget: Widget): widget is GraphWidget {
  return widget.type === WidgetType.GRAPH_WIDGET;
}

export function isTextWidget(widget: Widget): widget is TextWidget {
  return widget.type === WidgetType.TEXT_WIDGET;
}

export function isImageWidget(widget: Widget): widget is ImageWidget {
  return widget.type === WidgetType.IMAGE_WIDGET;
}

export interface CustomTableWidgetData {
  readonly body: {
    readonly headers: string[];
    readonly rows: (string | number)[][];
  };
}

export interface ResultTableWidgetData {
  readonly documentID: string;
  readonly documentTable: string;
  readonly gridState?: GridState;
  readonly numberOfRows?: number;
}

export interface SequenceLogoWidgetData {
  readonly body: {
    readonly sequence: string;
    readonly count: number;
    readonly type: string;
  }[];
  readonly settings?: SequenceLogoSettings;
}

export enum SummaryGraphType {
  BAR_CHART = 'bar-chart',
  STACKED_BAR_CHART = 'stacked-bar-chart',
  HEATMAP = 'heatmap',
}

export interface GraphWidgetData {
  readonly documentID?: string;
  readonly code: SummaryGraphType;
  readonly params: any;
  readonly options?: { [key: string]: any };
}

export interface BarChartOptions {
  readonly region?: GraphOption;
}

export interface HeatmapChartOptions {
  readonly region?: GraphOption;
  readonly length?: string;
  readonly gene?: GraphOption;
  readonly wrapped?: boolean;
  readonly transposed?: boolean;
  readonly reduced?: boolean;
}

interface StackedBarStyleOptions {
  readonly colorScheme?: string;
  readonly showLabels?: boolean;
  readonly showLegend?: boolean;
}

export interface StackedBarChartOptions extends StackedBarStyleOptions {
  readonly region?: GraphOption;
  readonly length?: number;
  readonly geneFamily?: GraphOption;
}

export interface ImageWidgetData {
  readonly documentID: string;
}

export interface SequenceLogoSettings extends StackedBarStyleOptions {
  readonly isSetToEntropy: boolean;
  readonly colorScheme: string;
}

export interface ReportWidgetComponent {
  widget: ReportWidget;
  showControls: boolean;
  widgetReady: EventEmitter<void>;
  widgetRemoved: EventEmitter<string>;
}

export function isReportState(json: ReportJson): json is ReportStateFlattened {
  return 'createdBy' in json;
}
