import { createAction, props, union } from '@ngrx/store';
import { ReportWidget } from '../report.model';
import { ReportStateFlattened } from './report-state';

export const hydrate = createAction(
  '[Report State] Hydrate',
  props<{ payload: { reportState: ReportStateFlattened } }>(),
);

export const newReport = createAction(
  '[Report State] New Report',
  props<{ payload: { title: string; date: Date; createdBy: string } }>(),
);

export const clearReport = createAction('[Report State] Clear Report');

export const updateTitle = createAction(
  '[Report State] Update Title',
  props<{ payload: { title: string } }>(),
);

export const updateNotes = createAction(
  '[Report State] Update Notes',
  props<{ payload: { notes: string } }>(),
);

export const updateDate = createAction(
  '[Report State] Update Date',
  props<{ payload: { date: Date } }>(),
);

export const updateCreatedBy = createAction(
  '[Report State] Update Created By',
  props<{ payload: { createdBy: string } }>(),
);

export const addWidget = createAction(
  '[Report State] Add Widget',
  props<{ payload: { widget: ReportWidget } }>(),
);

export const addWidgets = createAction(
  '[Report State] Add Widgets',
  props<{ payload: { widgets: ReportWidget[] } }>(),
);

export const updateWidget = createAction(
  '[Report State] Update Widget',
  props<{ payload: { widget: ReportWidget } }>(),
);

export const updateWidgetTitle = createAction(
  '[Report State] Update Widget Title',
  props<{ payload: { id: string; title: string } }>(),
);

export const updateWidgetNotes = createAction(
  '[Report State] Update Widget Notes',
  props<{ payload: { id: string; notes: string } }>(),
);

export const patchWidgetData = createAction(
  '[Report State] Patch Widget Data',
  props<{ payload: { id: string; data: Partial<ReportWidget['data']> } }>(),
);

export const removeWidget = createAction(
  '[Report State] Remove Widget',
  props<{ payload: { id: string } }>(),
);

const actions = union({
  hydrate,
  newReport,
  clearReport,
  updateTitle,
  updateNotes,
  updateDate,
  updateCreatedBy,
  addWidget,
  addWidgets,
  updateWidget,
  updateWidgetTitle,
  updateWidgetNotes,
  patchWidgetData,
  removeWidget,
});

export type ActionsUnion = typeof actions;
