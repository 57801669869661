import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'bx-collapsible-panel',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './collapsible-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsiblePanelComponent {
  @HostBinding('class') readonly hostClass = 'd-flex flex-grow-0 flex-shrink-0 overflow-hidden';

  @Input() collapsed = false;
  @Input() panelClass = 'w-static-1 p-3 overflow-auto';
  @Output() collapseChange = new EventEmitter<boolean>();

  readonly caretRight = faCaretRight;
  readonly caretLeft = faCaretLeft;

  togglePanel() {
    this.collapsed = !this.collapsed;
    this.collapseChange.emit(this.collapsed);
  }
}
