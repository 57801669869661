import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { GraphWidget, SummaryGraphType } from '../../report.model';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbaAllChartOptions, GraphAbaDataService } from '../../../graphs/graph-aba-data.service';
import { ReportStateFacade } from '../../report-store/report-state.facade';
import { GraphDatasource } from '../../../graphs/graph-sidebar';
import AbaHeatmapGraphDatasource from '../../../graphs/datasources/aba-heatmap-graph-datasource';
import AbaAminoAcidDistributionGraphDatasource from '../../../graphs/datasources/aba-amino-acid-distribution-graph-datasource';
import AbaColumnGraphDatasource from '../../../graphs/datasources/aba-column-graph-datasource';
import AbaGeneUsageDatasource from '../../../graphs/datasources/aba-gene-usage-datasource';
import { DocumentTableStateService } from '../../../../core/document-table-service/document-table-state/document-table-state.service';
import { DocumentTable } from '../../../../../nucleus/services/documentService/types';
import { BaseReportWidgetComponent } from '../../base-report-widget.component';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/core.store';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { ReportWidgetTitleComponent } from '../../report-widget-title/report-widget-title.component';
import { AsyncPipe } from '@angular/common';
import { ChartPresenterComponent } from '../../../graphs/chart-presenter/chart-presenter.component';

@Component({
  selector: 'bx-graph-widget',
  templateUrl: './graph-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatLegacyButtonModule,
    MatIconModule,
    ReportWidgetTitleComponent,
    ChartPresenterComponent,
    AsyncPipe,
  ],
})
export class GraphWidgetComponent extends BaseReportWidgetComponent<GraphWidget> implements OnInit {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column';
  @Input() widget: GraphWidget;
  @Input() showControls = true;
  @Output() widgetReady = new EventEmitter();
  @Output() widgetRemoved = new EventEmitter<string>();

  allOptions$: Observable<AbaAllChartOptions>;
  datasource$: Observable<GraphDatasource>;
  private tables$: Observable<Record<string, DocumentTable>>;

  constructor(
    private readonly dataService: GraphAbaDataService,
    private readonly reportStateFacade: ReportStateFacade,
    private readonly documentTableStateService: DocumentTableStateService,
    store: Store<AppState>,
  ) {
    super(store);
  }

  ngOnInit() {
    this.initWidget$();
    const { documentID, params, options: initialOptions } = this.widget.data;
    this.tables$ = this.documentTableStateService.getTablesMap(documentID);
    this.allOptions$ = this.dataService.getAllBarOptions(documentID);

    this.datasource$ = combineLatest([this.tables$, this.allOptions$]).pipe(
      map(([tables, options]) => {
        if (this.widget.data.code === SummaryGraphType.BAR_CHART) {
          return new AbaColumnGraphDatasource(
            this.dataService,
            documentID,
            tables,
            params,
            options,
            initialOptions,
          );
        } else if (this.widget.data.code === SummaryGraphType.STACKED_BAR_CHART) {
          if (params.source === 'json' && params.jsonChart === 'geneFamilyUsage') {
            return new AbaGeneUsageDatasource(
              this.dataService,
              documentID,
              tables,
              params,
              options,
              initialOptions,
            );
          } else {
            return new AbaAminoAcidDistributionGraphDatasource(
              this.dataService,
              documentID,
              tables,
              params,
              options,
              initialOptions,
            );
          }
        } else if (this.widget.data.code === SummaryGraphType.HEATMAP) {
          return new AbaHeatmapGraphDatasource(
            this.dataService,
            documentID,
            tables,
            params,
            options,
            initialOptions,
          );
        }
      }),
    );
  }

  setChartOptions(options: { [key: string]: any }) {
    this.patchWidgetData({ options });
  }

  titleChanged(title: string) {
    if (this.showControls) {
      this.reportStateFacade.updateWidgetTitle(this.widget.id, title);
    }
  }
}
