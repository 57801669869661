import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: '[bx-editable-text]',
  templateUrl: './editable-text.component.html',
  styleUrls: ['./editable-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TextFieldModule, FormsModule, ReactiveFormsModule, MatIconModule, AsyncPipe],
})
export class EditableTextComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('editableTextInput') editableTextInputElement: ElementRef;
  @Input('bx-editable-text') text: string;
  @Input() placeholder: string;
  @Output() editableTextChanged = new EventEmitter<string>();

  editing$ = new BehaviorSubject<boolean>(false);
  readonly formControl = new FormControl<string>(undefined);
  textContent: string;

  private subscriptions = new Subscription();

  constructor() {
    this.subscriptions.add(
      this.formControl.valueChanges.subscribe((text) => {
        this.textContent = text;
        this.editableTextChanged.emit(text);
      }),
    );

    this.subscriptions.add(
      this.editing$
        .pipe(
          filter((editing) => !editing),
          filter((_) => this.formControl.value != null && this.formControl.value.trim() === ''),
        )
        .subscribe((_) => this.formControl.setValue(this.text)),
    );
  }

  onTextClicked() {
    this.editing$.next(true);
    // the text input element won't be rendered until the next javascript cycle.
    setTimeout(() => {
      this.editableTextInputElement.nativeElement.focus();
    }, 0);
  }

  ngOnInit() {
    this.textContent = this.text;
  }

  ngOnChanges({ text }: SimpleChanges): void {
    if (text) {
      this.formControl.setValue(text.currentValue);
    }
  }

  ngAfterViewInit(): void {
    this.formControl.setValue(this.text);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.editing$.complete();
  }

  handleKeyPress(event: KeyboardEvent) {
    if (event.code === 'Enter' && !event.shiftKey) {
      this.editing$.next(false);
    }
  }
}
