import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  filter,
  map,
  shareReplay,
  takeUntil,
  withLatestFrom,
} from 'rxjs';
import { CleanUp } from 'src/app/shared/cleanup';
import {
  ColorPalette,
  ColorPaletteID,
  ColorPaletteKind,
  isColorPaletteID,
} from '../color-palette.model';
import { ColorPaletteService } from '../color-palette.service';
import { ColorPaletteComponent } from '../color-palette/color-palette.component';

/**
 * A component that displays a dropdown menu for selecting a color palette. Binds to a FormControl
 * containing a ColorPaletteID.
 */
@Component({
  selector: 'bx-select-color-palette',
  standalone: true,
  imports: [CommonModule, ColorPaletteComponent, NgbDropdownModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectColorPaletteComponent),
      multi: true,
    },
  ],
  templateUrl: './select-color-palette.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectColorPaletteComponent extends CleanUp implements ControlValueAccessor, OnInit {
  /**
   * Whether to display categorical or continuous color palettes. Defaults to false (continuous).
   */
  @Input() set isCategorical(isCategorical: boolean) {
    this.isCategorical$.next(isCategorical);
  }

  /**
   * Filters the categorical palettes that can be selected. Only palettes that have enough
   * colors will be displayed. Ignored when {@link isCategorical} is false.
   */
  @Input() set numCategories(numCategories: number | null) {
    this.numCategories$.next(numCategories);
  }

  /**
   * The maximum number of swatches to display.
   */
  @Input() numSwatches = 9;

  /**
   * The form control to bind to.
   */
  @Input() formControl: FormControl<ColorPaletteID | undefined>;

  /** Emits the currently selected palette */
  readonly selectedPalette$ = this.completeOnDestroy(
    new BehaviorSubject<ColorPalette | undefined>(undefined),
  );
  /** Emits true if the control should be disabled, or otherwise null (for binding to attr.disabled) */
  readonly disabled$ = this.completeOnDestroy(new BehaviorSubject<null | true>(null));
  /** Emits palettes to display as options in the dropdown */
  palettes$: Observable<ColorPalette[]>;
  /** The currently selected palette's name */
  selectedPaletteName$: Observable<string>;

  private readonly isCategorical$ = this.completeOnDestroy(new BehaviorSubject(false));
  private readonly numCategories$ = this.completeOnDestroy(
    new BehaviorSubject<number | null>(null),
  );
  /** onChange callback for ControlValueAccessor. */
  private onChange: (value: ColorPaletteID | undefined) => void;
  /** onTouched callback for ControlValueAccessor. */
  private onTouched: () => void;

  constructor(private readonly colorPaletteService: ColorPaletteService) {
    super();
  }

  ngOnInit(): void {
    this.palettes$ = combineLatest([this.isCategorical$, this.numCategories$]).pipe(
      map(([isCategorical, numCategories]) =>
        isCategorical ? { numCategories } : { kind: ColorPaletteKind.SEQUENTIAL },
      ),
      map((paletteFilter) => this.colorPaletteService.getPalettes(paletteFilter)),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );

    // Notify onChange when the selected palette changes
    this.selectedPalette$.pipe(withLatestFrom(this.disabled$)).subscribe(([palette, disabled]) => {
      if (this.onChange != null && !disabled) {
        this.onChange(palette.id);
      }
    });

    // Reset color palette when the currently selected one is invalid
    combineLatest([
      this.palettes$.pipe(map((palettes) => palettes.map((palette) => palette.id))),
      this.selectedPalette$,
    ])
      .pipe(
        filter(
          ([palettes, selectedPalette]) =>
            selectedPalette != null && !palettes.includes(selectedPalette.id),
        ),
        map(([palettes]) => palettes[0]),
      )
      .subscribe((paletteID) => {
        this.formControl.setValue(paletteID);
        this.writeValue(paletteID);
      });
  }

  onDropdownChange(opened: boolean) {
    if (!opened && this.onTouched != null) {
      this.onTouched();
    }
  }

  writeValue(obj: any): void {
    if (obj == null) {
      this.selectedPalette$.next(obj);
    } else if (isColorPaletteID(obj)) {
      this.selectedPalette$.next(ColorPalette[obj]);
    } else {
      throw new Error('Invalid Color Palette ID: ' + JSON.stringify(obj));
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled$.next(isDisabled || null);
  }
}
