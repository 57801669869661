@if (editing$ | async) {
  <span class="editable-wrapper">
    <textarea
      cdkTextareaAutosize
      #editableTextInput
      [placeholder]="placeholder"
      [formControl]="formControl"
      (blur)="editing$.next(false)"
      (keypress)="handleKeyPress($event)"
    ></textarea>
  </span>
} @else {
  <span class="text-wrapper" (click)="onTextClicked()">
    <span [className]="!textContent ? 'placeholder' : ''">{{ textContent || placeholder }}</span
    ><mat-icon>edit</mat-icon>
  </span>
}
