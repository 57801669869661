import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  MonoTypeOperatorFunction,
  Observable,
  ObservableInput,
  distinctUntilChanged,
  isObservable,
  map,
  startWith,
  takeUntil,
} from 'rxjs';

/**
 * Returns a form control validator function that wraps `Validators.required`,
 * adding the specified message when the control is invalid.
 *
 * @param message the error message
 * @returns a form validator function
 */
export function requiredWithMessage(message: string): ValidatorFn {
  return (ctrl) => (Validators.required(ctrl) ? { requiredMessage: message } : null);
}

/**
 * Returns an observable that emits the control's current value followed by any
 *  value changes. Shorthand for:
 * ```js
 * control.valueChanges.pipe(
 *    startWith(control.value),
 *    distinctUntilChanged(),
 *    takeUntil(unsubscribeNotifier) // optional
 * )
 * ```
 * Note the `distinctUntilChanged` operator: this will prevent duplicate values.
 * Most of the time this is beneficial for performance, but it does mean that
 * re-emissions triggered by `control.updateValueAndValidity()` will be blocked.
 *
 * @param control Form control
 * @param unsubscribeNotifier notifier to pass to `takeUntil`. If not specified,
 *    the `takeUntil` operator will not be applied. You should always provide this
 *    _unless_ you are going to pipe the returned observable through other
 *    operators: https://ncjamieson.com/avoiding-takeuntil-leaks/
 * @returns an observable that emits the control's value
 */
export function currentValueAndChanges<
  T,
  R extends T = T,
  C extends AbstractControl<T, R> = AbstractControl<T, R>,
>(control: C, unsubscribeNotifier?: Observable<unknown>): (typeof control)['valueChanges'];
/**
 * Returns an observable that emits the control's current value followed by any
 *  value changes. Shorthand for:
 * ```js
 * control.valueChanges.pipe(
 *    startWith(control.value),
 *    distinctUntilChanged(),
 *    takeUntilDestroyed() // optional
 * )
 * ```
 * Note the `distinctUntilChanged` operator: this will prevent duplicate values.
 * Most of the time this is beneficial for performance, but it does mean that
 * re-emissions triggered by `control.updateValueAndValidity()` will be blocked.
 *
 * @param control Form control
 * @param unsubscribeOperator Operator such as `takeUntilDestroyed`. You should
 *    always provide this _unless_ you are going to pipe the returned observable
 *    through other operators: https://ncjamieson.com/avoiding-takeuntil-leaks/
 * @returns an observable that emits the control's value
 */
export function currentValueAndChanges<
  T,
  R extends T = T,
  C extends AbstractControl<T, R> = AbstractControl<T, R>,
>(control: C, unsubscribeOperator?: MonoTypeOperatorFunction<T>): (typeof control)['valueChanges'];
export function currentValueAndChanges<
  T,
  R extends T = T,
  C extends AbstractControl<T, R> = AbstractControl<T, R>,
>(
  control: C,
  unsubscribe?: Observable<unknown> | MonoTypeOperatorFunction<T>,
): (typeof control)['valueChanges'] {
  if (!unsubscribe) {
    return control.valueChanges.pipe(startWith(control.value), distinctUntilChanged());
  }
  const unsubscribeOperator = isObservable(unsubscribe) ? takeUntil<T>(unsubscribe) : unsubscribe;
  return control.valueChanges.pipe(
    startWith(control.value),
    distinctUntilChanged(),
    unsubscribeOperator,
  );
}

/** The type of the value returned by {@link FormGroup.getRawValue} */
export type FormRawValue<T extends FormGroup> = ReturnType<T['getRawValue']>;

/**
 * Returns a stream of a changes to a value in a form, accessed via a parent
 * form group's form's {@link FormGroup#getRawValue} method. This is useful for
 * watching a control's value changes even when the control is disabled, as
 * disabled controls do not emit valueChanges events or run validators - but
 * they can have their values changed programmatically.
 *
 * @param parentGroup the form group to watch valueChanges on. This method
 *    assumes that this form group is not disabled, otherwise its valueChanges
 *    won't emit either.
 * @param valueGetter a function that extracts the desired value from the form's
 *    raw value
 * @param unsubscribeNotifier notifier to pass to `takeUntil` to prevent memory
 *    leaks
 * @param comparator optional comparator to pass to `distinctUntilChanged` so
 *    that the returned observable doesn't emit every time the parent group's
 *    value changes.
 * @returns a stream of values that emits immediately, and then on value change
 *    until `unsubscribeNotifier` emits.
 * @example Enable a control so its validators will mark it invalid when its
 *    value is changed programmatically.
 * ```ts
   rawValueChanges(
      this.form,
      (formValue) => formValue.nameSchemeID,
      this.ngUnsubscribe
   ).subscribe((selectedNameSchemeID) =>
     setEnabled(nameSchemeControl, selectedNameSchemeID !== validNameSchemeID)
   );
 * ```
 */
export function rawValueChanges<T extends FormGroup, R>(
  parentGroup: T,
  valueGetter: (formRawValue: FormRawValue<T>) => R,
  unsubscribeNotifier: ObservableInput<unknown>,
  comparator?: (prev: R, current: R) => boolean,
): Observable<R> {
  return parentGroup.valueChanges.pipe(
    startWith(null),
    map(() => valueGetter(parentGroup.getRawValue())),
    distinctUntilChanged(comparator),
    takeUntil(unsubscribeNotifier),
  );
}

/**
 * Enables or disables a form control.
 *
 * @param control the form control
 * @param enabled pass true to enable the control, or false to disable it
 */
export function setEnabled(control: AbstractControl, enabled: boolean) {
  if (enabled) {
    control.enable();
  } else {
    control.disable();
  }
}

/**
 * Options for a helper function to subscribe to a form control's value changes.
 */
type ControlValueStreamConfig<T> =
  | {
      /**
       * An unsubscribe notifier that will passed to {@link currentValueAndChanges}
       */
      takeUntil: Observable<unknown>;
    }
  | {
      /**
       * An alternative value stream. For example, you may want to use
       * {@link rawValueChanges} to watch value changes on a disabled control.
       */
      value$: Observable<T>;
    };

type EqualityPredicate<T> = {
  /**
   * A function that tests whether two values are equal. Defaults to strict
   * equals (`===`).
   */
  isEqual?: (a: T, b: T) => boolean;
};

type DefaultValue<T> = {
  /**
   * The value to set on the form control when it is set to an invalid value.
   * Defaults to undefined.
   */
  defaultValue?: T | null | undefined;
};

/**
 * Returns the value stream according to the config object.
 *
 * @param config the value stream config
 * @param control the form control
 * @returns a stream of the control's values
 * @see {@link ControlValueStreamConfig}
 */
function getValueStream<T>(
  config: ControlValueStreamConfig<T>,
  control: FormControl<T>,
): Observable<T> {
  if ('value$' in config) {
    return config.value$;
  }
  return currentValueAndChanges(control, config.takeUntil);
}

/**
 * Compares a value to a list of allowed values, either returning the instance
 * recognized by the form control or the default value if it is not found.
 *
 * @param allowedValues the permitted values for the form control
 * @param value the value to check
 * @param config options for overriding the equality function and default value
 * @returns a value recognized by the form control, and a boolean indicating
 *    whether the input value matched an allowed value
 */
function getAllowedValue<T>(
  allowedValues: T[],
  value: T,
  { isEqual = (a, b) => a === b, defaultValue }: EqualityPredicate<T> & DefaultValue<T>,
): { value: T; matchFound: true } | { value: T | null | undefined; matchFound: false } {
  const matchingValueIndex = allowedValues.findIndex((allowedValue) =>
    isEqual(value, allowedValue),
  );
  if (matchingValueIndex === -1) {
    return { value: defaultValue, matchFound: false };
  }
  return { value: allowedValues[matchingValueIndex], matchFound: true };
}

/**
 * Ensures a form control's value is one of the allowed values. If it is set to
 * an unrecognized value, it will be reset to the default value. This is useful
 * for ensuring form controls with dynamic values (e.g. name schemes, reference
 * databases, regions) do not get set to a value that is not valid for the
 * selection. For example, an options profile could contain a region that is not
 * present for the current document.
 *
 * @param control the form control
 * @param allowedValues the permitted values for the form control
 * @param config options for the method. Must contain either `value$`, which
 *    will be used as the value stream, or `takeUntil`, in which case
 *    `currentValueAndChanges(control, config.takeUntil)` will be used. It is
 *    also possible to override the `equals` function used to test equality, and
 *    the `defaultValue` that the control gets set to when the value is invalid.
 */
export function restrictControlValue<T>(
  control: FormControl<T>,
  allowedValues: T[],
  config: ControlValueStreamConfig<T> & EqualityPredicate<T> & DefaultValue<T>,
): void {
  getValueStream(config, control).subscribe((ctrlValue) => {
    const allowed = getAllowedValue(allowedValues, ctrlValue, config);
    // Address the issue where select doesn't recognize a different instance of the same value
    if (ctrlValue !== allowed.value) {
      control.setValue(allowed.value);
    }
  });
}

/**
 * Ensures a form control with an array value only contains allowed values. Any
 * unrecognized values added to the array will be removed. This is useful for
 * ensuring form controls with dynamic values (e.g. name schemes, reference
 * databases, regions) do not get set to values that are not valid for the
 * selection. For example, an options profile could contain a region that is not
 * present for the current document.
 *
 * @param control the form control
 * @param allowedValues the permitted values for the form control
 * @param config options for the method. Must contain either `value$`, which
 *    will be used as the value stream, or `takeUntil`, in which case
 *    `currentValueAndChanges(control, config.takeUntil)` will be used. It is
 *    also possible to override the `equals` function used to test equality.
 */
export function restrictControlValues<T>(
  control: FormControl<T[]>,
  allowedValues: T[],
  config: ControlValueStreamConfig<T[]> & EqualityPredicate<T>,
): void {
  getValueStream(config, control)
    .pipe(map((values) => (Array.isArray(values) ? values : [])))
    .subscribe((values) => {
      const validValues: T[] = [];
      let hasInvalidValue = false;
      for (const value of values) {
        const result = getAllowedValue(allowedValues, value, config);
        if (result.matchFound) {
          validValues.push(result.value);
        }
        // Address the issue where select doesn't recognize a different instance of the same value
        if (result.value !== value || !result.matchFound) {
          hasInvalidValue = true;
        }
      }
      if (hasInvalidValue) {
        control.setValue(validValues);
      }
    });
}
