import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';
import * as d3 from 'd3';
import { BehaviorSubject } from 'rxjs';
import { ZoomableChart } from '../../../core/ngs/ngs-graphs/graph-zoom/graph-zoom.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'bx-graph-zoom-container',
  templateUrl: './graph-zoom-container.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class GraphZoomContainerComponent implements ZoomableChart {
  @Input() zoomMin: number;
  @Input() zoomMax: number;
  @ViewChild('graph') container: ElementRef<HTMLDivElement>;
  private svg: d3.Selection<SVGSVGElement, undefined, null, undefined>;
  private absoluteDimensions: AbsoluteDimensions;
  private zoom: d3.ZoomBehavior<any, any>;
  private currentZoom$ = new BehaviorSubject<number>(1);
  private zoomScrollState: 'idle' | 'zoom' | 'scroll' | 'zoomscroll' = 'idle';
  @HostBinding('class') readonly hostClass =
    'w-100 h-100 d-flex flex-grow-1 flex-shrink-1 overflow-hidden';

  setSVG(svg: d3.Selection<SVGSVGElement, undefined, null, undefined>) {
    this.svg = svg;
  }

  setKeyEventListeners() {
    d3.select('#zoom-container')
      .on('click', () => {
        // allow text (e.g. tree tip labels) to be de-selected.
        // without this, users have to click outside the entire graph container to deselect accidental selections
        if (window.getSelection()?.empty) {
          window.getSelection().empty();
        }
      })
      .on('mouseover', () => {
        this.container.nativeElement.focus({ preventScroll: true });
      })
      .on('mouseout', () => {
        this.container.nativeElement.blur();
      })
      .on('contextmenu', (e) => {
        // disable right-click menu for now, because it causes trouble.
        // in the future we will implement our own.
        e.preventDefault();
      })
      .on('keydown', (event: KeyboardEvent) => {
        const key = event.key;
        const isCmdOrCtrl = event.metaKey || event.ctrlKey;
        const arrowKey = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(key);
        const zoomKey = isCmdOrCtrl && ['0', '-', '=', '+'].includes(key);

        if (arrowKey) {
          if (key === 'ArrowLeft') {
            this.pan(10, 0);
          } else if (key === 'ArrowRight') {
            this.pan(-10, 0);
          } else if (key === 'ArrowDown') {
            this.pan(0, -10);
          } else if (key === 'ArrowUp') {
            this.pan(0, 10);
          }
        }
        if (zoomKey) {
          if (key === '0') {
            this.zoomToFit();
          } else if (key === '-') {
            this.zoomOut();
          } else if (key === '=' || key === '+') {
            this.zoomIn();
          }
        }
        if (arrowKey || zoomKey) {
          event.preventDefault();
          event.stopPropagation();
        }
      });
  }

  zoomDiscrete(zoomOut: boolean) {
    this.updateZoomScales();
    const { scale } = this.getAbsoluteDimensionsForFit();
    const currentZoomLevel = this.currentZoom$.value ?? 1;
    const [minZoom, maxZoom] = this.zoom.scaleExtent();
    const ZOOM_LEVELS = [0.5, 0.75, 1, 1.25, 1.5, 2, 5, 10, 50]
      .map((x) => x * scale)
      .filter((x) => x <= maxZoom && x >= minZoom);
    if (!ZOOM_LEVELS.includes(minZoom)) {
      ZOOM_LEVELS.push(minZoom);
    }
    if (!ZOOM_LEVELS.includes(maxZoom)) {
      ZOOM_LEVELS.push(maxZoom);
    }
    ZOOM_LEVELS.sort((a, b) => (zoomOut ? b - a : a - b));
    const zoomLevelsIndex = ZOOM_LEVELS.indexOf(currentZoomLevel);
    if (zoomLevelsIndex === ZOOM_LEVELS.length - 1) {
      return;
    }
    let nextZoomLevelIndex;
    const activeTransition = d3.active(this.svg.node(), 'zoomDiscrete');
    if (zoomLevelsIndex === ZOOM_LEVELS.length - 1) {
      nextZoomLevelIndex = ZOOM_LEVELS.length - 1;
    } else if (zoomLevelsIndex === -1) {
      const nextIndex = ZOOM_LEVELS.findIndex((level) =>
        zoomOut ? level < currentZoomLevel : level > currentZoomLevel,
      );
      nextZoomLevelIndex = activeTransition
        ? Math.min(nextIndex + 1, ZOOM_LEVELS.length - 1)
        : (nextIndex ?? ZOOM_LEVELS.length - 1);
    } else {
      nextZoomLevelIndex = Math.min(zoomLevelsIndex + 1, ZOOM_LEVELS.length - 1);
    }
    const nextZoomLevel = ZOOM_LEVELS[nextZoomLevelIndex];

    this.svg.transition('zoomDiscrete').call(this.zoom.scaleTo, nextZoomLevel);

    this.currentZoom$.next(nextZoomLevel);
  }

  public zoomIn() {
    this.zoomDiscrete(false);
  }

  public zoomOut() {
    this.zoomDiscrete(true);
  }

  public zoomToFit() {
    this.updateZoomScales();
    const { minX, minY, maxX, maxY, scale } = this.absoluteDimensions.forFit();
    const centreX = (minX + maxX) / 2;
    const centreY = (minY + maxY) / 2;
    this.svg.call(this.zoom.scaleTo, scale);
    this.svg.call(this.zoom.translateTo, centreX, centreY);
    this.currentZoom$.next(scale);
  }

  public updateZoomScales() {
    this.absoluteDimensions.update();
    const { minX, minY, maxX, maxY, scale } = this.absoluteDimensions.forBounds();
    this.zoom
      .scaleExtent([
        Math.min(scale, this.zoomMin * scale * 2),
        Math.max(this.zoomMax * scale * 2, 10 * scale),
      ])
      .translateExtent([
        [minX, minY],
        [maxX, maxY],
      ]);
  }

  public getZoomLevel() {
    return this.currentZoom$
      .asObservable()
      .pipe(map((k) => k / (this.getAbsoluteDimensionsForFit()?.scale ?? 1)));
  }

  public setCurrentZoom(k: number) {
    this.currentZoom$.next(k);
  }

  public getZoomLevelValue() {
    return this.currentZoom$.value;
  }

  public setZoomBehaviour(zoom: typeof this.zoom) {
    this.zoom = zoom;
  }

  public pan(x: number, y: number) {
    this.svg.call(this.zoom.translateBy, x, y);
  }

  public getZoom() {
    return this.zoom;
  }

  public setScrollEventListeners(offsetByViewport: boolean) {
    d3.select('#scroll-bar-x').on('scroll', () => {
      const { width } = this.absoluteDimensions.getDimensionsForZoom();
      if (this.zoomScrollState === 'zoom') {
        return;
      }
      const wrapperNode = d3.select('#scroll-bar-x').node() as any;
      const { x, k } = this.zoomTransform;
      const { minX, maxX, scaleX } = this.absoluteDimensions.forBounds();
      if (k <= scaleX) {
        return;
      }
      this.zoomScrollState = 'scroll';
      const interpolateX = wrapperNode.scrollLeft;
      const fracX = interpolateX / (width * (k / scaleX - 1));
      const viewPortX = ((maxX - minX) * scaleX) / k;
      const viewPortOffset = offsetByViewport ? viewPortX / 2 : 0;
      const minXValue = minX + viewPortOffset;
      const maxXValue = maxX - viewPortX + viewPortOffset;
      const xValue = fracX * (maxXValue - minXValue) + minXValue;
      this.svg.call(this.getZoom().translateBy, -xValue - x / k, 0);
    });
    d3.select('#scroll-bar-y').on('scroll', () => {
      const { height } = this.absoluteDimensions.getDimensionsForZoom();
      if (this.zoomScrollState === 'zoom') {
        return;
      }
      const wrapperNode = d3.select('#scroll-bar-y').node() as any;
      const { y, k } = this.zoomTransform;
      const { minY, maxY, scaleY } = this.absoluteDimensions.forBounds();
      if (k <= scaleY) {
        return;
      }
      this.zoomScrollState = 'scroll';
      const interpolateY = wrapperNode.scrollTop;
      const fracY = interpolateY / (height * (k / scaleY - 1));
      const viewPortY = ((maxY - minY) * scaleY) / k;
      const viewPortOffset = offsetByViewport ? viewPortY / 2 : 0;
      const minYValue = minY + viewPortOffset;
      const maxYValue = maxY - viewPortY + viewPortOffset;
      const yValue = fracY * (maxYValue - minYValue) + minYValue;
      this.svg.call(this.getZoom().translateBy, 0, -yValue - y / k);
    });
  }

  setScrollBarsToZoomPosition(x: number, y: number, k: number, offsetByViewport: boolean) {
    const { width, height } = this.absoluteDimensions.getDimensionsForZoom();
    const { maxX, minX, maxY, minY, scaleY, scaleX } = this.absoluteDimensions.forBounds();
    d3.select('#scroll-content-x').style('width', `calc(100% * ${k / scaleX})`);
    d3.select('#scroll-content-y').style('height', `calc(100% * ${k / scaleY})`);
    if (k >= scaleY) {
      const viewPortY = ((maxY - minY) * scaleY) / k;
      const viewPortOffset = offsetByViewport ? viewPortY / 2 : 0;
      const minYValue = minY + viewPortOffset;
      const maxYValue = maxY - viewPortY + viewPortOffset;
      const yValue = -y / k;
      const fracY = (yValue - minYValue) / (maxYValue - minYValue);
      (d3.select('#scroll-bar-y').node() as HTMLDivElement).scrollTop =
        fracY * height * (k / scaleY - 1);
    }
    if (k >= scaleX) {
      const viewPortX = ((maxX - minX) * scaleX) / k;
      const viewPortOffset = offsetByViewport ? viewPortX / 2 : 0;
      const minXValue = minX + viewPortOffset;
      const maxXValue = maxX - viewPortX + viewPortOffset;
      const xValue = -x / k;
      const fracX = (xValue - minXValue) / (maxXValue - minXValue);
      (d3.select('#scroll-bar-x').node() as HTMLDivElement).scrollLeft =
        fracX * width * (k / scaleX - 1);
    }
  }

  public setScrollState(scrollState: typeof this.zoomScrollState) {
    this.zoomScrollState = scrollState;
  }

  public getScrollState() {
    return this.zoomScrollState;
  }

  public get zoomTransform() {
    if (this.svg?.node()) {
      return d3.zoomTransform(this.svg.node());
    }
    return d3.zoomIdentity;
  }

  public getContainer() {
    return this.container;
  }

  public getContainerRect() {
    return this.container.nativeElement.getBoundingClientRect();
  }

  public setDimensions(dimensions: (container: ElementRef<HTMLDivElement>) => AbsoluteDimensions) {
    this.absoluteDimensions = dimensions(this.container);
  }

  public getAbsoluteDimensionsForFit() {
    return this.absoluteDimensions.forFit();
  }

  public getAbsoluteDimensionsForBounds() {
    return this.absoluteDimensions.forBounds();
  }

  public updateAbsoluteDimensions() {
    return this.absoluteDimensions.update();
  }

  public getDimensionsForZoom() {
    return this.absoluteDimensions.getDimensionsForZoom();
  }
}

export interface AbsoluteDimensionsType {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
  scaleX: number;
  scaleY: number;
  scale: number;
}
export abstract class AbsoluteDimensions {
  protected absoluteDimensions: AbsoluteDimensionsType;
  protected constructor(protected container: any) {}
  public abstract forFit(): typeof this.absoluteDimensions;
  public forBounds(): typeof this.absoluteDimensions {
    const { minX, maxX, minY, maxY, scaleX, scaleY, scale } = this.forFit();
    return {
      minX: minX - (maxX - minX) / 2,
      maxX: maxX + (maxX - minX) / 2,
      minY: minY - (maxY - minY) / 2,
      maxY: maxY + (maxY - minY) / 2,
      scaleX: scaleX / 2,
      scaleY: scaleY / 2,
      scale: scale / 2,
    };
  }
  public abstract update(): void;
  public getDimensionsForZoom() {
    const { width, height } = this.container.nativeElement.getBoundingClientRect();
    // a width of 1 will hopefully be very temporary!
    return {
      width: Math.max(1, width - 20),
      height,
    };
  }
}
