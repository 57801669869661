import {
  GraphControlTypeEnum,
  GraphDatasource,
  GraphDatasourceError,
  GraphSidebarControls,
  GraphSidebarDatasourceResponse,
  GraphTypeEnum,
} from '../graph-sidebar';
import { AbaAllChartOptions, GraphAbaDataService, GraphOption } from '../graph-aba-data.service';
import { SummaryGraphParams } from '../../../core/ngs/ngs-summary-graph-viewer/ngs-summary-graph-viewer.component';
import { IStackedBarInfo } from '../stacked-column-chart/stacked-bar-info.model';
import { StackedBarChartOptions } from '../../report/report.model';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import { firstValueFrom } from 'rxjs';
import { StackedColumnColorSchemeHandler } from './stacked-column-colorscheme-handler';

export default class AbaGeneUsageDatasource implements GraphDatasource {
  private readonly geneFamilies: GraphOption[];

  private data: IStackedBarInfo;
  private colorSchemeHandler: StackedColumnColorSchemeHandler;
  constructor(
    private dataService: GraphAbaDataService,
    private readonly documentID: string,
    private tables: Record<string, DocumentTable>,
    private params: SummaryGraphParams,
    private allOptions: AbaAllChartOptions,
    private initialOptions?: StackedBarChartOptions,
  ) {
    this.colorSchemeHandler = new StackedColumnColorSchemeHandler('AminoAcid');
    this.geneFamilies = this.allOptions.geneFamilies;
  }

  validate(): GraphDatasourceError | null {
    if (this.geneFamilies.length === 0) {
      return {
        controls: [],
        error:
          'There is no Gene Family Usage data for this result. To view this graph you will need to re-run your analysis.',
      };
    }
    return null;
  }

  async init(): Promise<GraphSidebarDatasourceResponse> {
    const validation = this.validate();
    if (validation) {
      return Promise.resolve(validation);
    }
    return this.getGeneUsageData(
      this.initialOptions?.geneFamily ?? this.geneFamilies[0],
      this.initialOptions?.showLegend,
    );
  }

  controlValueChanged(
    previousOptions: GeneUsageSidebarOptions<string>,
    options: GeneUsageSidebarOptions<string>,
  ): Promise<GraphSidebarDatasourceResponse> {
    const validation = this.validate();
    if (validation) {
      return Promise.resolve(validation);
    }
    const geneFamily = this.geneFamilies.find((r) => r.name === options.geneFamily);
    if (previousOptions.geneFamily !== options.geneFamily) {
      return this.getGeneUsageData(geneFamily, options.showLegend);
    } else {
      const currentOptions = {
        geneFamily: geneFamily,
        showLegend: options.showLegend,
      };
      return Promise.resolve(this.formatData(this.data, currentOptions, false));
    }
  }

  private getGeneUsageData(
    geneFamily: GraphOption,
    showLegend: boolean,
  ): Promise<GraphSidebarDatasourceResponse> {
    return firstValueFrom(
      this.dataService.getStackedBarChartData(this.params, this.documentID, {
        geneFamily,
      }),
    ).then((data) => {
      this.data = data;
      return this.formatData(data, {
        geneFamily,
        showLegend,
      });
    });
  }

  private formatData(
    chart: IStackedBarInfo,
    options: GeneUsageSidebarOptions<GraphOption>,
    animations = true,
  ): GraphSidebarDatasourceResponse {
    const controls = this.generateControls(options);
    if (chart?.data?.length === 0) {
      return {
        error: `No data found for ${options.geneFamily.name}`,
        controls,
      };
    }

    return {
      graph: {
        data: chart.data,
        title: chart.title,
        xAxisTitle: 'Gene Family',
        yAxisTitle: 'Frequency',
        stacking: 'normal',
        showLabels: false,
        showLegend: options.showLegend,
        animations: animations,
        type: GraphTypeEnum.STACKED_COLUMN,
      },
      controls,
      options,
    };
  }

  private generateControls(options: GeneUsageSidebarOptions<GraphOption>): GraphSidebarControls {
    return [
      {
        name: 'geneFamily',
        label: 'Gene',
        type: GraphControlTypeEnum.SELECT,
        defaultOption: options.geneFamily.name,
        options: this.geneFamilies.map(({ name }) => {
          return {
            displayName: name,
            value: name,
          };
        }),
      },
      this.colorSchemeHandler.getLegendControl(options.showLegend),
    ];
  }
}

interface GeneUsageSidebarOptions<T extends string | GraphOption> {
  geneFamily: T;
  showLegend: boolean;
}
